var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal, center: true },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "card-body p-4",
          on: {
            keypress: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _c("span", { staticClass: "form-label mr-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("advert_duration")) + " ")
          ]),
          _vm._v(" "),
          _c("date-picker", {
            staticClass: "w-100",
            staticStyle: { width: "100%" },
            attrs: {
              id: "chartDate",
              type: "datetimerange",
              "range-separator": "—",
              "start-placeholder": _vm.$t("graph_start_date"),
              "end-placeholder": _vm.$t("graph_end_date")
            },
            model: {
              value: _vm.duration,
              callback: function($$v) {
                _vm.duration = $$v
              },
              expression: "duration"
            }
          }),
          _vm._v(" "),
          _c("mega-input", {
            staticClass: "mb-0 mt-3",
            attrs: {
              type: "text",
              label: _vm.$t("advert_result_link"),
              placeholder: "https://"
            },
            model: {
              value: _vm.result_link,
              callback: function($$v) {
                _vm.result_link = $$v
              },
              expression: "result_link"
            }
          }),
          _vm._v(" "),
          _c("mega-number", {
            staticClass: "mb-3 mt-3",
            attrs: {
              type: "number",
              min: 1000,
              step: 100,
              help: "Не меньше 1000",
              label: _vm.$t("advert_frequency")
            },
            model: {
              value: _vm.show_count,
              callback: function($$v) {
                _vm.show_count = $$v
              },
              expression: "show_count"
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "navbar sm-down:flex-column-reverse md-up:justify-content-end"
            },
            [
              _vm.round.adcomp
                ? _c(
                    "mega-button",
                    {
                      staticClass:
                        "bg-danger sm-down:w-100 md-up:mr-2 text-white",
                      on: { click: _vm.archiveAdvert }
                    },
                    [_vm._v(_vm._s(_vm.$t("del_adcomp")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "bg-white sm-down:w-100 md-up:mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary sm-down:w-100 sm-down:mb-3",
                  attrs: { disabled: _vm.isDisabled },
                  on: { click: _vm.submit }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.round.adcomp
                        ? _vm.$t("change")
                        : _vm.$t("advert_round")
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }