var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "mega-card",
        { staticClass: "rounded h-100 mb-0" },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  _vm.$route.name === "Dashboard"
                    ? _vm.$emit("open-qr", _vm.arr["idt_arp"])
                    : (_vm.modal = !_vm.modal)
                }
              }
            },
            [
              _c(
                "IconBase",
                {
                  staticClass: "qr-scan cursor-pointer text-muted",
                  staticStyle: { "z-index": "10" },
                  attrs: { viewBox: "0 0 24 24", width: 24, height: 24 }
                },
                [_c("ScanQR")],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "navbar bg-white border-bottom" },
            [
              _c(
                "router-link",
                {
                  staticClass: "container-fluid",
                  attrs: {
                    to: { name: "Business", params: { id: _vm.arr.idt_arp } }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "w-100 navbar-label mr-auto text-dark py-3"
                    },
                    [
                      _c("mega-image", {
                        staticClass: "rounded-full navbar-avatar mr-3",
                        staticStyle: { overflow: "hidden" },
                        attrs: { ratio: "1x1", small: "", src: _vm.arr.logo }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "content",
                          staticStyle: { width: "calc(100% - 78px)" }
                        },
                        [
                          _c("small", { staticClass: "strong text-overflow" }, [
                            _vm._v(_vm._s(_vm.arr.name) + " ")
                          ]),
                          _vm._v(" "),
                          _c(
                            "small",
                            { staticClass: "text-muted text-overflow" },
                            [_vm._v(_vm._s("@arr_" + _vm.arr.idt_arp))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("nav", { staticClass: "nav row font-small w-100 pl-3" }, [
            _c("div", { staticClass: "nav-text text-muted col-8 p-0 pl-2" }, [
              _c("i", { staticClass: "i-globe nav-icon" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { "events-disable text-dark": !_vm.arr.site },
                  attrs: {
                    href: _vm.arr.site && _vm.arr.site,
                    target: "_blank"
                  }
                },
                [_vm._v(_vm._s(_vm.arr.site || "- - -"))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav-text text-muted p-0 col-4" }, [
              _c("i", { staticClass: "i-heart nav-icon" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.arrLikes || "- - -"))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav-text text-muted col-8 p-0 pl-2" }, [
              _c("i", { staticClass: "i-phone nav-icon" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { "events-disable text-dark": !_vm.arr.phone },
                  attrs: { href: _vm.arr.phone && "tel:" + _vm.arr.phone }
                },
                [_vm._v(_vm._s(_vm.arr.phone || "- - -"))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav-text text-muted p-0 col-4" }, [
              _c("i", { staticClass: "i-eye nav-icon" }),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.arrRoundViews || "- - -"))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "nav-text text-muted col-8 p-0 pl-2" }, [
              _c("i", { staticClass: "i-user-plus nav-icon" }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$u2d(_vm.arr["a_time"], "DD.MM - LT") || "- - -")
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "nav-text text-muted col-4 p-0" },
              [
                _c(
                  "IconBase",
                  {
                    staticStyle: { "margin-right": "0px" },
                    attrs: { viewBox: "-73 0 512 512", width: 15, height: 15 }
                  },
                  [_c("Click")],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.arrTransitions || "- - -"))])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "mega-modal",
            {
              ref: "business-qr",
              staticClass: "modal-sm",
              attrs: { active: _vm.modal, center: true },
              on: {
                onClose: function($event) {
                  _vm.modal = false
                },
                click: function($event) {
                  _vm.modal = !_vm.modal
                }
              }
            },
            [
              _c("qr-code", {
                attrs: { uri: "type=arr&id=" + _vm.arr["idt_arp"] }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }