let Render = function(image) {
  return new Promise(function(resolve, reject) {
    if (!image) resolve("");

    let img = new Image();
    img.onload = () => {
      resolve(img.src);
    };
    img.src = image;
  });
};

export default {
  install: function(Vue) {
    Vue.prototype.$render = Render;
  }
};
