var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d:
        "M33.266 21.377L40.502 21.101L40.924 23.89H33.406L33.861 32.101H30.502V13.101L42.51 13.375V15.773H32.955L33.266 21.377ZM16.577 13.823L15.382 16.7C12.904 15.778 5.29799 14.568 5.29799 22.836C5.29799 26.974 6.92599 29.53 10.797 29.53C12.384 29.53 13.916 29.187 15.045 28.844V24.444L10.502 25.1L9.77499 21.738H17.545V30.188C17.195 30.425 14.292 32.112 10.502 32.112C5.20499 32.112 2.48999 28.475 2.48999 23.02C2.48999 10.762 14.239 12.483 16.577 13.823ZM22.673 13.375H25.361L25.725 32.101H22.166L22.673 13.375Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }