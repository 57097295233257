import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import channels from "./modules/channels";

const API_URL = process.env.API_URL || "https://arapi.gora.studio";
const WS_URL =
  process.env.NODE_ENV === "production"
    ? "wss://msg.dgr.am"
    : "wss://arround-msg.gora.studio";
const MSG_API_URL =
  process.env.MSG_API || "wss://arround-msg.gora.studio/v2/ws";
const MSG_URL =
  process.env.NODE_ENV === "production"
    ? "https://msg.dgr.am"
    : "https://arround-msg.gora.studio";

let Api = {
  v1: axios.create({
    baseURL: API_URL + "/v1/admin"
  }),

  v2: axios.create({
    baseURL: API_URL + "/v2/admin"
  }),

  msg: axios.create({
    baseURL: MSG_URL + "/v3"
  })
};

Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    state: () => ({
      isProd: process.env.NODE_ENV === "production",
      user: null,
      token: null,
      lang: null,
      msgSocket: null,
      msgApi: MSG_API_URL,
      unreadMessages: [],
      selectedSn: null,
      isBoundAdmin: null
    }),

    actions: {
      async fetchInitial({ commit, getters }) {
        Vue.axios.defaults.headers.common[
          "x-access-token"
        ] = getters.getToken();
        axios.defaults.headers.common["x-access-token"] = getters.getToken();
        Api.v1.defaults.headers.common["x-access-token"] = getters.getToken();
        Api.v2.defaults.headers.common["x-access-token"] = getters.getToken();

        try {
          const response = await Api.v1.get("/profile");
          const { profile } = response.data;

          commit("setUser", profile);

          return response.data;
        } catch (error) {
          console.error(error);
        }
      },
      async login({ commit }, data) {
        return axios.post("/login-session", data).then(res => {
          commit("setToken", res.data.token);
          console.log(res);
          localStorage.setItem("token", res.data.token);
          return res.data;
        });
      },
      logout({ commit }, data) {
        localStorage.removeItem("token");
        commit("setUser", null);
        commit("setToken", null);
        commit("setPage", "");
        return axios.delete("/login-session", {
          data: { idt_session: data.idt_session }
        });
      },
      // clear({ commit }) {
      //   commit("setUser", null);
      //   commit("setToken", null);
      //   commit("setPage", "");
      // },
      language({ commit }, data) {
        commit("setLanguage", data);
        localStorage.setItem("language", data);
      },
      async openMsgSocket({ commit, state }) {
        return new Promise(resolve => {
          const token = state.token;
          const socket = new WebSocket(
            `${WS_URL}/v3/admin/support/stream?token=${token}`
          );
          commit("setMessageSocket", socket);
          resolve();
        });
      }
    },

    mutations: {
      setUser(state, data) {
        Vue.set(state, "user", data);
      },
      setToken(state, data) {
        Vue.set(state, "token", data);
      },
      setLanguage(state, data) {
        Vue.set(state, "lang", data);
      },
      setMessageSocket(state, data) {
        state.msgSocket = data;
      },
      setUnreadMessages(state, data) {
        state.unreadMessages = data;
      },
      clearUnreadMessages(state, chat_id) {
        state.unreadMessages = state.unreadMessages.filter(id => id != chat_id);
      },
      setSelectedSn(state, data) {
        localStorage.setItem("selectedSn", JSON.stringify(data));
        state.selectedSn = data;
      },
      setIsBoundAdmin(state, isBoundAdmin) {
        state.isBoundAdmin = isBoundAdmin;
      }
    },

    getters: {
      getToken: state => () => {
        return state.token;
      },
      selectedSnId: state => {
        if (state.selectedSn) {
          return state.selectedSn.id;
        }
        return undefined;
      }
    },

    modules: {
      channels: channels
    }
  });
}
