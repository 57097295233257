var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-card",
    { staticClass: "rounded h-100 mb-0" },
    [
      _c(
        "div",
        {
          staticClass: "navbar border-bottom rounded-top p-relative",
          style: _vm.userData["is_influencer"]
            ? "background-color: rgba(2,140,255, 0.27)"
            : ""
        },
        [
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  _vm.$route.name === "Dashboard"
                    ? _vm.$emit("open-qr", _vm.userData["idt_user"])
                    : (_vm.modal = !_vm.modal)
                }
              }
            },
            [
              _c(
                "IconBase",
                {
                  staticClass: "qr-scan cursor-pointer text-muted",
                  staticStyle: { "z-index": "10" },
                  attrs: {
                    viewBox: "0 0 24 24",
                    width: 24,
                    height: 24,
                    iconName: _vm.$t("qr_code")
                  }
                },
                [_c("ScanQR")],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pt-2 pl-2 d-flex flex-column w-80" },
            [
              _c(
                "router-link",
                {
                  staticClass: "w-100 navbar-label mr-auto text-dark pb-2",
                  attrs: {
                    to: {
                      name: "User",
                      params: { id: _vm.user ? _vm.user.idt_user : "" }
                    }
                  }
                },
                [
                  _c("mega-image", {
                    staticClass: "rounded-full navbar-avatar mr-3",
                    staticStyle: { overflow: "hidden" },
                    attrs: { small: "", ratio: "1x1", src: _vm.userData.avatar }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "content",
                      staticStyle: { width: "calc(100% - 78px)" }
                    },
                    [
                      _c(
                        "small",
                        {
                          staticClass:
                            "strong d-flex justify-content-between align-items-center"
                        },
                        [
                          _c("span", { staticClass: "text-overflow" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.userData.name || "- - -") +
                                "\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "ml-2",
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.makeVerified.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _vm.userData.verified
                                ? _c(
                                    "IconBase",
                                    {
                                      attrs: {
                                        viewBox: "0 0 312 311",
                                        width: 20,
                                        iconColor: "#45C1C9"
                                      }
                                    },
                                    [_c("UserVerified")],
                                    1
                                  )
                                : _c(
                                    "IconBase",
                                    {
                                      attrs: {
                                        viewBox: "0 0 312 311",
                                        width: 20,
                                        iconColor: "#bababa"
                                      }
                                    },
                                    [_c("UserVerified")],
                                    1
                                  )
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("small", { staticClass: "text-muted text-overflow" }, [
                        _vm.device
                          ? _c(
                              "span",
                              {
                                class: {
                                  "text-success": _vm.device === "i-android"
                                },
                                attrs: { title: _vm.userTitle }
                              },
                              [
                                _c("i", { class: _vm.device }),
                                _vm._v(" -\n            ")
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n\n            " +
                            _vm._s(_vm.userData.username) +
                            "\n          "
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-start flex-column" },
                [
                  _c("mega-switch", {
                    attrs: {
                      smaller: true,
                      label: _vm.$t("influencer"),
                      checked: _vm.userData.is_influencer
                    },
                    on: { click: _vm.makeInfluencer }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { on: { click: _vm.openPromoModal } },
            [
              _vm.userData.is_influencer
                ? _c(
                    "IconBase",
                    {
                      staticClass:
                        "promo-code mt-auto ml-auto mr-1 cursor-pointer text-muted",
                      staticStyle: { "z-index": "10" },
                      attrs: {
                        viewBox: "0 0 510 510",
                        width: 24,
                        height: 24,
                        iconName: _vm.$t("promocode")
                      }
                    },
                    [_c("PromoCode")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("nav", { staticClass: "nav row font-small w-100 pl-3" }, [
        _c("div", { staticClass: "nav-text text-muted col-8 p-0 pl-2" }, [
          _c("i", { staticClass: "i-mail nav-icon" }),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "events-disable text-dark": !_vm.userData.email },
              attrs: {
                href: _vm.userData.email && "mailto:" + _vm.userData.email
              }
            },
            [_vm._v(_vm._s(_vm.userData.email || "- - -"))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nav-text text-muted col-4 p-0" }, [
          _c("i", { staticClass: "i-eye nav-icon" }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.roundviews || "- - -"))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nav-text text-muted col-8 p-0 pl-2" }, [
          _c("i", { staticClass: "i-phone nav-icon" }),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { "events-disable text-dark": !_vm.userData.phone },
              attrs: { href: _vm.userData.phone && "tel:" + _vm.userData.phone }
            },
            [_vm._v(_vm._s(_vm.userData.phone || "- - -"))]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nav-text text-muted col-4 p-0" }, [
          _c("i", { staticClass: "i-heart nav-icon" }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.roundlikes || "- - -"))])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nav-text text-muted col-8 p-0 pl-2" }, [
          _c("i", { staticClass: "i-user-plus nav-icon" }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.$u2d(_vm.userData["a_time"], "DD.MM.Y") || "- - -")
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nav-text text-muted col-4 p-0" }, [
          _c("i", { staticClass: "i-layers nav-icon" }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.userData["round_count"] || "- - -"))])
        ])
      ]),
      _vm._v(" "),
      _c(
        "mega-modal",
        {
          ref: "qr",
          staticClass: "modal-sm",
          attrs: { active: _vm.modal, center: true },
          on: {
            onClose: function($event) {
              _vm.modal = false
            },
            click: function($event) {
              _vm.modal = !_vm.modal
            }
          }
        },
        [
          _c("qr-code", {
            attrs: { uri: "type=prof&id=" + _vm.userData["idt_user"] }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("promo-modal", {
        ref: "promo",
        attrs: {
          userData: _vm.user,
          roundlikes: _vm.roundlikes,
          roundviews: _vm.roundviews
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }