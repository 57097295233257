import AdminLayout from "../templates/layouts/admin.vue";
import Dashboard from "../templates/pages/Dashboard";

const _import = file => () => import(`../templates/pages/${file}`);

export default function() {
  return [
    {
      path: "/login",
      name: "Login",
      component: _import("Login"),
      meta: {
        guest: true
      }
    },
    {
      path: "/recovery",
      name: "Recovery",
      component: _import("Recovery"),
      meta: {
        guest: true
      }
    },
    {
      path: "/",
      component: AdminLayout,
      children: [
        {
          path: "/",
          name: "Dashboard",
          component: Dashboard
        },

        {
          path: "/moderation",
          name: "Moderation",
          component: _import("Moderation")
        },

        {
          path: "/statistics",
          name: "Statistics",
          component: _import("Statistics")
        },

        {
          path: "/users",
          name: "Users",
          component: _import("Users")
        },

        {
          path: "/support/:chat?",
          name: "Support",
          component: _import("Support")
        },
        {
          path: "/user/:id",
          name: "User",
          props: true,
          component: _import("User")
        },
        {
          path: "/user/:user_id/rounds",
          name: "User_Rounds",
          props: true,
          component: _import("UserRounds")
        },
        {
          path: "/user/:user_id/map",
          name: "User_Map",
          props: true,
          component: _import("Rounds_Map")
        },
        {
          path: "/business/:arr_id/rounds",
          name: "Business_Rounds",
          props: true,
          component: _import("Rounds")
        },
        {
          path: "/rounds/:type",
          name: "Rounds",
          props: true,
          component: _import("Rounds")
        },
        {
          path: "/rounds/:type/map",
          name: "Rounds_Map",
          props: true,
          component: _import("Rounds_Map")
        },
        {
          path: "/rounds/:type/fav",
          name: "Rounds_Fav",
          props: true,
          component: _import("Rounds")
        },
        {
          path: "/rounds/:type/promoted",
          name: "Rounds_Promoted",
          props: true,
          component: _import("Rounds")
        },
        {
          path: "/round/post",
          name: "Round_Post",
          component: _import("Round_Post")
        },
        {
          path: "/round/post/3d",
          name: "Round_Post_3D",
          component: _import("Round_Post_3D")
        },
        {
          path: "/round/post/gif",
          name: "Round_Post_Gif",
          component: _import("Round_Post_Gif")
        },
        {
          path: "/round/post/photo",
          name: "Round_Post_Photo",
          component: _import("Round_Post_Photo")
        },
        {
          path: "/round/post/youtube",
          name: "Round_Post_Youtube",
          component: _import("Round_Post_Youtube")
        },
        {
          path: "/round/post/video",
          name: "Round_Post_Video",
          component: _import("Round_Post_Video")
        },
        {
          path: "/round/post/longvideo",
          name: "Round_Post_LongVideo",
          component: _import("Round_Post_LongVideo")
        },
        {
          path: "/round/:roundId",
          name: "Round",
          props: true,
          component: _import("Round")
        },
        {
          path: "/mask/:model_id/:modification_id?",
          name: "Mask",
          props: true,
          component: _import("Model-Combi")
        },
        {
          path: "/image/:model_id/:modification_id?",
          name: "Image",
          props: true,
          component: _import("Model-Combi")
        },
        {
          path: "/video/:model_id/:modification_id?",
          name: "Video",
          props: true,
          component: _import("Model-Combi")
        },
        {
          path: "/3d/:model_id/:modification_id?",
          name: "Model",
          props: true,
          component: _import("Model-Combi")
        },
        {
          path: "/portal/:model_id/:modification_id?",
          name: "Portal",
          props: true,
          component: _import("Model-Combi")
        },
        {
          path: "/models/:roundType/:is_active?/:groupId?",
          name: "Models",
          props: true,
          component: _import("Models")
        },
        {
          path: "/gif/:model_id/:modification_id?",
          name: "Gif",
          props: true,
          component: _import("Model-Combi")
        },

        {
          path: "/ref_image/new/:modelId/:modId",
          name: "Ref_Image_New",
          props: true,
          component: _import("Ref_Image_New")
        },
        {
          path: "/ref_image/:refImageId",
          name: "Ref_Image_Edit",
          props: true,
          component: _import("Ref_Image_Edit")
        },
        {
          path: "/businesses",
          name: "Businesses",
          props: true,
          component: _import("Businesses")
        },
        {
          path: "/businesses/favourite",
          name: "Businesses_fav",
          props: true,
          component: _import("Businesses_fav")
        },
        {
          path: "/business/:id",
          name: "Business",
          props: true,
          component: _import("Business")
        },
        {
          path: "/social_networks",
          name: "Sns",
          props: true,
          component: _import("Sns")
        },
        {
          path: "/social_network/create",
          name: "Create_Sn",
          props: false,
          component: _import("Create_Sn")
        },
        {
          path: "/social_network/:id?",
          name: "Sn",
          props: true,
          component: _import("Sn")
        },
        {
          path: "/payouts",
          name: "Payouts",
          component: _import("Payouts")
        },
        {
          path: "/options",
          name: "Options",
          component: _import("Options")
        },
        {
          path: "/streams",
          name: "Streams",
          component: _import("Streams")
        },
        {
          path: "/categories/:category_type",
          name: "Categories",
          component: _import("Categories")
        },

        {
          path: "/influencers",
          name: "Influencers",
          component: _import("Users")
        },

        {
          path: "/channels",
          name: "Channels",
          component: _import("Channels")
        },

        {
          path: "*",
          name: "PageNotFound",
          component: _import("Error_404")
        }
      ]
    }
  ];
}
