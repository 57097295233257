class Check {
  constructor(prop) {
    this.run = true;
    this.value = prop;
  }

  error(ch, msg, callback) {
    if (this.run) {
      if (ch(this.value)) {
        if (msg) console.error(msg);
        this.run = false;
      }

      if (callback) callback(this.value);
    }

    return this;
  }

  warn(ch, msg, callback) {
    if (this.run) {
      if (ch(this.value)) {
        if (msg) console.warn(msg);
        this.run = false;
      }

      if (callback) callback(this.value);
    }

    return this;
  }

  then(ch, callback) {
    if (this.run) {
      if (ch) {
        if (ch(this.value)) callback(this.value);
      } else if (callback) callback(this.value);
    }

    return this;
  }
}

export default {
  install: function(Vue) {
    Vue.prototype.$check = v => new Check(v);
  }
};
