var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d:
        "M38 29.6691C35.0325 35.6903 32.8698 39.2562 31.5119 40.3669C29.475 42.0328 27.6912 42.4297 23.6 42.4297C19.5088 42.4297 17.5 42.4297 15.5 40.3669C14.1667 38.9916 10.8333 33.7027 5.5 24.5L3.5 20.5L4.5 5.5L6.5 3.5C11.8035 2.5 17.1715 2 22.6042 2C28.0368 2 33.3354 2.5 38.5 3.5L40.5 5.5L41 20.5L38 29.6691ZM38.429 19.356L37.65 5.48C32.6174 4.44335 27.6021 3.98541 22.6042 4.10617C17.6063 4.22693 12.3162 4.93388 6.734 6.227L6 19.356L8 23.6691C11.8689 31.6519 14.4701 36.3935 15.8034 37.8939C17.8034 40.1446 20.0996 40.2287 23.6 40.2287C27.1004 40.2287 28.0041 40.1578 30.5 37.8939C32.1639 36.3847 33.8306 33.4764 35.5 29.1691L38.429 19.356ZM14.5 18.5C12.843 18.5 11.5 17.157 11.5 15.5C11.5 13.843 12.843 12.5 14.5 12.5C16.156 12.5 17.5 13.843 17.5 15.5C17.5 17.157 16.156 18.5 14.5 18.5ZM30.5 18.5C28.844 18.5 27.5 17.157 27.5 15.5C27.5 13.843 28.844 12.5 30.5 12.5C32.157 12.5 33.5 13.843 33.5 15.5C33.5 17.157 32.157 18.5 30.5 18.5ZM27.814 27.4876L27.8141 27.4876C28.1877 26.8448 28.2346 26.7641 29.2004 27.3459C29.3021 27.4072 29.3936 27.461 29.4755 27.5091L29.4757 27.5092C29.8259 27.715 29.9993 27.817 30.0321 27.9529C30.0644 28.0862 29.9613 28.2523 29.7569 28.5813C29.7041 28.6664 29.6444 28.7624 29.5786 28.8716C28.778 30.1998 26.4805 31.1355 25.0091 31.5001C24.5266 31.6197 24.0252 31.6779 23.5166 31.6683C21.4573 31.6294 18.5594 30.4854 17.5634 28.6829C17.4865 28.5277 17.4162 28.3988 17.3567 28.2896C17.22 28.0391 17.1401 27.8926 17.1674 27.7699C17.2059 27.5971 17.4569 27.4716 18.0615 27.1694L18.0636 27.1683C18.2857 27.0573 18.4399 26.9586 18.5546 26.8853C18.6942 26.796 18.775 26.7443 18.8478 26.7538C18.9477 26.7668 19.0327 26.8951 19.2342 27.1995L19.2342 27.1995C19.308 27.311 19.3974 27.4461 19.5089 27.6078C20.3291 28.7969 22.2906 29.422 23.5584 29.4459C24.8258 29.47 27.0204 28.8099 27.6747 27.724C27.7231 27.6439 27.7662 27.5697 27.8058 27.5017L27.814 27.4876Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }