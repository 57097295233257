var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M133.906 306.859C151.625 324.719 168.078 324.578 185.797 306.859L207.453 285.344C209.703 283.234 211.672 282.391 214.625 282.391H245C270.172 282.391 281.844 270.859 281.844 245.547V215.172C281.844 212.219 282.688 210.25 284.797 208L306.312 186.344C324.172 168.625 324.031 152.172 306.312 134.453L284.797 112.797C282.688 110.688 281.844 108.578 281.844 105.766V75.25C281.844 50.2188 270.312 38.4062 245 38.4062H214.625C211.672 38.4062 209.703 37.7031 207.453 35.5938L185.797 14.0781C168.078 -3.78125 151.625 -3.64062 133.906 14.0781L112.25 35.5938C110.141 37.7031 108.031 38.4062 105.219 38.4062H74.7031C49.5312 38.4062 37.8594 49.9375 37.8594 75.25V105.766C37.8594 108.578 37.1562 110.688 35.0469 112.797L13.5312 134.453C-4.32812 152.172 -4.1875 168.625 13.5312 186.344L35.0469 208C37.1562 210.25 37.8594 212.219 37.8594 215.172V245.547C37.8594 270.719 49.5312 282.391 74.7031 282.391H105.219C108.031 282.391 110.141 283.234 112.25 285.344L133.906 306.859ZM136.156 231.203C131.797 231.203 128.984 229.797 126.875 227.406L79.9062 175.234C77.9375 172.984 76.8125 170.172 76.8125 167.219C76.8125 160.609 81.875 155.688 89.0469 155.688C92.8438 155.688 95.6562 156.953 98.1875 159.625L135.453 200.828L207.875 98.875C210.688 94.7969 213.5 93.1094 218.422 93.1094C225.312 93.1094 230.234 98.1719 230.234 104.781C230.234 107.172 229.25 109.984 227.703 112.234L145.719 226.844C143.609 229.516 140.375 231.203 136.156 231.203Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }