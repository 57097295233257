const _import = file => () => import(`./components/${file}`);

import Menu from "./modules/menu";

const Omega = {
  install(Vue) {
    Vue.prototype.$mega = {
      menu: Menu
    };

    Vue.component("mega-card", _import("card.vue"));
    Vue.component("mega-alert", _import("alert.vue"));
    Vue.component("mega-header", _import("header.vue"));
    Vue.component("mega-button", _import("button.vue"));
    Vue.component("mega-image", _import("image.vue"));
    Vue.component("mega-video", _import("video.vue"));
    Vue.component("mega-modal", _import("modal.vue"));
    Vue.component("mega-menu-link", _import("menu-link.vue"));
    Vue.component("mega-menu-group", _import("menu-group.vue"));

    Vue.component("mega-input", _import("form-input.vue"));
    Vue.component("mega-mask", _import("form-mask.vue"));
    Vue.component("mega-select", _import("form-select.vue"));
    Vue.component("mega-textarea", _import("form-textarea.vue"));
    Vue.component("mega-switch", _import("form-switch.vue"));
    Vue.component("mega-range", _import("form-range.vue"));
    Vue.component("mega-number", _import("form-number.vue"));
    Vue.component("mega-progress", _import("progress.vue"));
    Vue.component("mega-drop-zone", _import("drop-zone.vue"));

    Vue.component("mega-slider", _import("slider.vue"));
    Vue.component("mega-slide", _import("slide.vue"));
    Vue.component("mega-chart", _import("chart.vue"));
    Vue.component("mega-chart-set", _import("chart-set.vue"));
    Vue.component("mega-progress-ring", _import("progress-ring.vue"));

    Vue.component("mega-youtube", _import("youtube.vue"));
    Vue.component("mega-btn-upload", _import("button-upload.vue"));
    Vue.component("mega-radio", _import("radio.vue"));

    Vue.component("mega-loading", _import("loading/index.js"));

    Vue.component("mega-dropdown", _import("dropdown.vue"));
  }
};

export default Omega;
