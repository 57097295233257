<template>
  <mega-card>
    <div class="card-body" style="overflow: hidden;">
      <div class="qr-text pb-2">
        {{ $t("qr_text") }}
      </div>
      <div class="qr-image-wrapper">
        <!-- <mega-image class="qr-image" v-if="imageCode" :src="" ratio="1x1" save-as-img /> -->
        <div
          class="qr-image"
          :style="{ backgroundImage: 'url(' + imageCode + ')' }"
        >
          <div class="qr-image-overlay">
            <a download="qr.png" :href="imageCode">{{ $t("download") }}</a>
          </div>
        </div>
      </div>
      <div class="qr-text pt-2">{{ fullURI }}</div>
      <div ref="qrcode"></div>
    </div>
    <no-ssr> </no-ssr>
  </mega-card>
</template>

<style>
.qr-text {
  font-size: 0.8rem;
  line-height: 1.5;
}
.qr-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 340px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.qr-image-overlay {
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: absolute;
  bottom: 0;
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
}
.qr-image:hover .qr-image-overlay {
  opacity: 1;
}
</style>

<script>
import QrCodeWithLogo from "qr-code-with-logo";

export default {
  data() {
    return {
      imageCode: ""
    };
  },
  props: {
    uri: {
      type: String,
      required: true,
      default: null
    }
  },
  computed: {
    fullURI() {
      const baseUri =
        process.env.NODE_ENV === "production"
          ? "https://arround.world/app/"
          : "https://ar.arround.world/";

      return `${baseUri}?${this.uri}`;
    }
  },
  methods: {
    test(dataUrl, id) {
      console.log(url, id);
    },
    copy() {
      const input = this.$refs["input"].$el.querySelector("input");
      input.select();
      document.execCommand("copy");
    },
    createQr() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const nodeQrCodeOptions = {
        margin: 0,
        color: {
          dark: "#5cc8c8",
          light: "#0000"
        }
      };
      const logo = {
        src: require("../../assets/logo-sphere.svg"),
        borderRadius: 100,
        logoSize: 0.2,
        bgColor: "#fff",
        borderColor: "red",
        borderSize: 0.05
      };

      canvas.width = 1000;
      canvas.height = 1000;
      canvas.style.top = "-9999999px";
      canvas.style.left = "-9999999px";
      canvas.style.position = "absolute";
      canvas.style.pointerEvents = "none";
      canvas.style.visibility = "hidden";
      canvas.style.imageRendering = "pixelated";
      canvas.style.imageRendering = "crisp-edges";
      ctx.rect(0, 0, 1000, 1000);
      ctx.fillStyle = "#ffffff";
      ctx.fill();

      document.body.appendChild(canvas);

      QrCodeWithLogo.toCanvas({
        canvas: canvas,
        content: this.fullURI,
        width: 500,
        nodeQrCodeOptions,
        logo
      }).then(() => {
        this.$nextTick(() => {
          this.imageCode = canvas.toDataURL();
          canvas.remove();
        });
      });
    }
  },

  watch: {
    uri() {
      this.createQr();
    }
  },

  mounted() {
    this.createQr();
  }
};
</script>
