<template>
  <mega-card class="rounded h-100 mb-0">
    <div
      class="navbar border-bottom rounded-top p-relative"
      :style="
        userData['is_influencer']
          ? 'background-color: rgba(2,140,255, 0.27)'
          : ''
      "
    >
      <div
        @click="
          $route.name === 'Dashboard'
            ? $emit('open-qr', userData['idt_user'])
            : (modal = !modal)
        "
      >
        <IconBase
          viewBox="0 0 24 24"
          :width="24"
          :height="24"
          class="qr-scan cursor-pointer text-muted"
          style="z-index: 10"
          :iconName="$t('qr_code')"
          ><ScanQR
        /></IconBase>
      </div>
      <div class="pt-2 pl-2 d-flex flex-column w-80">
        <router-link
          :to="{ name: 'User', params: { id: user ? user.idt_user : '' } }"
          class="w-100 navbar-label mr-auto text-dark pb-2"
        >
          <mega-image
            class="rounded-full navbar-avatar mr-3"
            small
            ratio="1x1"
            :src="userData.avatar"
            style="overflow:hidden;"
          />

          <div class="content" style="width: calc(100% - 78px);">
            <small
              class="strong d-flex justify-content-between align-items-center"
            >
              <span class="text-overflow">
                {{ userData.name || "- - -" }}
              </span>
              <div class="ml-2" @click.prevent="makeVerified">
                <IconBase
                  v-if="userData.verified"
                  viewBox="0 0 312 311"
                  :width="20"
                  iconColor="#45C1C9"
                  ><UserVerified
                /></IconBase>
                <IconBase
                  v-else
                  viewBox="0 0 312 311"
                  :width="20"
                  iconColor="#bababa"
                  ><UserVerified
                /></IconBase>
              </div>
            </small>
            <small class="text-muted text-overflow">
              <span
                :title="userTitle"
                v-if="device"
                :class="{ 'text-success': device === 'i-android' }"
              >
                <i :class="device"></i> -
              </span>

              {{ userData.username }}
            </small>
          </div>
        </router-link>
        <div class="d-flex justify-content-start flex-column">
          <mega-switch
            :smaller="true"
            :label="$t('influencer')"
            :checked="userData.is_influencer"
            @click="makeInfluencer"
          />
          <!-- <mega-switch
            :smaller="true"
            :label="$t('user_verified')"
            :checked="userData.verified"
            @click="makeVerified"
          /> -->
        </div>
      </div>
      <div @click="openPromoModal">
        <IconBase
          v-if="userData.is_influencer"
          viewBox="0 0 510 510"
          :width="24"
          :height="24"
          class="promo-code mt-auto ml-auto mr-1 cursor-pointer text-muted"
          style="z-index: 10"
          :iconName="$t('promocode')"
          ><PromoCode
        /></IconBase>
      </div>
    </div>

    <nav class="nav row font-small w-100 pl-3">
      <div class="nav-text text-muted col-8 p-0 pl-2">
        <i class="i-mail nav-icon"></i>
        <a
          :class="{ 'events-disable text-dark': !userData.email }"
          :href="userData.email && 'mailto:' + userData.email"
          >{{ userData.email || "- - -" }}</a
        >
      </div>

      <div class="nav-text text-muted col-4 p-0">
        <i class="i-eye nav-icon"></i>
        <span>{{ roundviews || "- - -" }}</span>
      </div>

      <div class="nav-text text-muted col-8 p-0 pl-2">
        <i class="i-phone nav-icon"></i>
        <a
          :class="{ 'events-disable text-dark': !userData.phone }"
          :href="userData.phone && 'tel:' + userData.phone"
          >{{ userData.phone || "- - -" }}</a
        >
      </div>

      <div class="nav-text text-muted col-4 p-0">
        <i class="i-heart nav-icon"></i>
        <span>{{ roundlikes || "- - -" }}</span>
      </div>

      <div class="nav-text text-muted col-8 p-0 pl-2">
        <i class="i-user-plus nav-icon"></i>
        <span>{{ $u2d(userData["a_time"], "DD.MM.Y") || "- - -" }}</span>
      </div>

      <div class="nav-text text-muted col-4 p-0">
        <i class="i-layers nav-icon"></i>
        <span>{{ userData["round_count"] || "- - -" }}</span>
      </div>
    </nav>
    <mega-modal
      ref="qr"
      class="modal-sm"
      :active="modal"
      :center="true"
      @onClose="modal = false"
      @click="modal = !modal"
    >
      <qr-code :uri="'type=prof&id=' + userData['idt_user']" />
    </mega-modal>
    <promo-modal
      ref="promo"
      :userData="user"
      :roundlikes="roundlikes"
      :roundviews="roundviews"
    />
  </mega-card>
</template>

<script>
import IconBase from "../../../../components/icons/IconBase.vue";
import UserVerified from "../../../../components/icons/set/UserVerified.vue";
import ScanQR from "../../../../components/icons/set/ScanQR";
import PromoCode from "../../../../components/icons/set/Promo-code";
import QrCode from "../../../components/QrCode";
import PromoModal from "./promo-modal.vue";

export default {
  props: {
    data: Object
  },
  data() {
    return {
      user: this.data,
      roundviews: "",
      roundlikes: "",
      modal: false,
      promoModal: ""
    };
  },
  components: {
    IconBase,
    UserVerified,
    ScanQR,
    QrCode,
    PromoCode,
    PromoModal
  },
  mounted() {},
  computed: {
    userData() {
      return this.user;
    },
    userTitle() {
      if (this.user["sessions"]) {
        return this.user["sessions"][0]
          ? this.$u2d(this.user["sessions"][0]["a_time"], "DD.MM.YYYY - LT")
          : "";
      }
      return "";
    },
    device() {
      let d = undefined;

      if (this.user["sessions"]) {
        if (!this.user["sessions"][0]) return d;

        let s = this.user["sessions"][0]["user_agent"].toLowerCase();

        d = "i-monitor";
        if (s.includes("ios")) d = "i-apple";
        if (s.includes("android")) d = "i-android";
      }

      return d;
    }
  },
  methods: {
    getInfo() {
      this.$api.v1
        .get("/user", { params: { id: this.data.idt_user } })
        .then(response => {
          this.user = response.data.user;
        });
    },
    getViews() {
      this.$api.v2base
        .get("/stat/user/roundviews/year", {
          params: {
            idt_user_owner: this.data.idt_user,
            start_date: "02-01-2006 15:04:05"
          }
        })
        .then(response => {
          this.roundviews = response.data[0] ? response.data[0].count : "";
        });
    },
    getLikes() {
      this.$api.v2base
        .get("/stat/user/roundlikes/year", {
          params: {
            idt_user_owner: this.data.idt_user,
            start_date: "02-01-2006 15:04:05"
          }
        })
        .then(response => {
          this.roundlikes = response.data[0] ? response.data[0].count : "";
        });
    },
    makeInfluencer() {
      this.$api.v2base
        .post("/user/influencers", {
          idt_user: this.user.idt_user,
          value: !this.user.is_influencer
        })
        .then(() => {
          this.user.is_influencer = !this.user.is_influencer;
          this.$alert.success(this.$t("success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },
    makeVerified() {
      this.$api.v2base
        .put("/user/verify", {
          idt_user: this.user.idt_user,
          verified: !this.user.verified
        })
        .then(() => {
          this.user.verified = !this.user.verified;
          this.$alert.success(this.$t("success"));
        })
        .catch(() => {
          this.$alert.danger(this.$t("err_server"));
        });
    },
    openPromoModal() {
      this.getInfo();
      this.getViews();
      this.getLikes();
      this.$refs["promo"].open();
    }
  }
};
</script>

<style lang="sass" scoped>
.nav-icon
  margin-right: 7px !important
.qr-scan
  position: absolute
  top: 5px
  right: -8px
.promo-code
  position: absolute
  bottom: 5px
  right: 3px
</style>
