<template>
  <mega-select
    :label="label"
    :placeholder="placeholder"
    v-model="_value"
    @select="$emit('select', $event)"
    :options="groups"
  />
</template>

<script>
export default {
  props: {
    label: String,
    placeholder: String,
    options: Array,
    type: Number,
    value: [String, Number]
  },
  data() {
    return {
      groups: []
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    type() {
      this.load();
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.groups = [];

      let params = {
        limit: 500,
        offset: 0,
        idc_round_type: 4,
        sn_id: this.$store.getters.selectedSnId
      };

      if (this.type) params.idc_round_type = this.type;

      this.$api.v2
        .get("/groups", { params })
        .then(response => response.data.groups)
        .then(groups => {
          groups = groups || [];

          this.groups.push({ value: 0, name: this.$t("wo_group") });

          groups.map(item => {
            this.groups.push({ value: item.idt_group, name: item.name });
          });
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    }
  }
};
</script>
