import { render, staticRenderFns } from "./Side3D.vue?vue&type=template&id=4daa919a&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4daa919a')) {
      api.createRecord('4daa919a', component.options)
    } else {
      api.reload('4daa919a', component.options)
    }
    module.hot.accept("./Side3D.vue?vue&type=template&id=4daa919a&", function () {
      api.rerender('4daa919a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/set/Side3D.vue"
export default component.exports