import Vue from "vue";
require("./ssr");

import Omega from "../omega";
Vue.use(Omega);

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDjIFzw30eG8-MVTHVXY8VLtPdHI0y1TNA",
    libraries: "places"
  }
});

Vue.prototype.$maps = VueGoogleMaps;

import Alertify from "../scripts/alertify";
Vue.use(Alertify);

import PerfectScrollbar from "vue2-perfect-scrollbar";
Vue.use(PerfectScrollbar);

import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);
