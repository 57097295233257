var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d:
        "M41 32.5L29.718 26.859L30 32.5L28 34.5H6L4 32.5L5 12.5L7 10.5H27L29 12.5L29.293 18.354L41 12.5V32.5ZM25.898 13.43L7.742 12.619L6.586 32.529L27.115 32.5L25.898 13.43ZM38.486 17.016L29.396 20.44L29.605 24.609L38.857 29.015L38.486 17.016Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }