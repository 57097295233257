import alertifyjs from "alertifyjs";

export default {
  install: function(Vue) {
    Vue.prototype.$alert = {
      success: msg => {
        alertifyjs.success(
          '<div class="d-flex" style="line-height: 20px;"><i class="i-check-circle text-success mr-3" style="position: relative; top: 3px;"></i> <div><span>' +
            msg +
            "</span></div></div>"
        );
      },
      danger: msg => {
        alertifyjs.error(
          '<div class="d-flex" style="line-height: 20px;"><i class="i-close-circle text-danger mr-3" style="position: relative; top: 3px;"></i> <div><span>' +
            msg +
            "</span></div></div>"
        );
      },
      info: msg => {
        alertifyjs.message(
          '<div class="d-flex" style="line-height: 20px;"><i class="i-info text-primary mr-3" style="position: relative; top: 3px;"></i> <div><span>' +
            msg +
            "</span></div></div>"
        );
      }
    };
  }
};
