import Vue from "vue";

let Navbar = new Vue({
  data() {
    return {
      name: ""
    };
  }
});

export default {
  install: function(Vue) {
    Vue.prototype.$navbar = Navbar;
  }
};
