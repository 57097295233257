var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-card",
    {
      staticClass: "rounded",
      attrs: {
        menu: [
          {
            icon: _vm.model.is_active ? "i-eye" : "i-eye-off",
            click: _vm.toggleActive
          }
        ]
      }
    },
    [
      _c(
        "router-link",
        {
          staticClass: "card-media",
          attrs: {
            to: {
              name: _vm.type,
              params: {
                model_id: _vm.model.idt_model,
                modification_id: _vm.modif.idt_model_modif
              }
            }
          }
        },
        [
          _vm.image
            ? _c("mega-image", { attrs: { ratio: "1x1", src: _vm.preview } })
            : _c("mega-video", {
                attrs: { ratio: "1x1", card: true, src: _vm.preview }
              })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "nav" }, [
        _c(
          "div",
          { staticClass: "p-2 d-flex flex-wrap align-items-center w-100" },
          [
            !_vm.refs
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center text-center w-100",
                    style: { height: "42px" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-muted w-100",
                        style: { fontSize: "14px" }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("card_refs_status")) + "\n        "
                        )
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.refs, function(ref) {
              return _c("div", {
                key: ref.idt_ref_image,
                staticClass: "mr-2",
                style: {
                  backgroundImage: `url(${ref.url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: "42px",
                  height: "42px",
                  border: "none",
                  cursor: "pointer",
                  flex: "0 0 42px"
                },
                on: {
                  click: function($event) {
                    _vm.$route.name === "Dashboard"
                      ? _vm.$emit("openpreview", ref.url)
                      : _vm.openPreview(ref.url)
                  }
                }
              })
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav" },
        [
          _c(
            "router-link",
            {
              staticClass: "btn w-100 text-muted",
              attrs: {
                to: {
                  name: _vm.type,
                  params: {
                    model_id: _vm.model.idt_model,
                    modification_id: _vm.modif.idt_model_modif
                  }
                }
              }
            },
            [_vm._v(_vm._s(_vm.model.name))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("ImagePreview", { ref: "preview", attrs: { ratio: "1x1" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }