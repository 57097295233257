<template>
  <mega-card
    class="rounded"
    :menu="[
      { icon: model.is_active ? 'i-eye' : 'i-eye-off', click: toggleActive }
    ]"
  >
    <router-link
      class="card-media"
      :to="{
        name: type,
        params: {
          model_id: model.idt_model,
          modification_id: modif.idt_model_modif
        }
      }"
    >
      <mega-image v-if="image" ratio="1x1" :src="preview" />
      <mega-video v-else ratio="1x1" :card="true" :src="preview" />
    </router-link>

    <div class="nav">
      <div class="p-2 d-flex flex-wrap align-items-center w-100">
        <div
          v-if="!refs"
          :style="{ height: '42px' }"
          class="d-flex align-items-center justify-content-center text-center w-100"
        >
          <span class="text-muted w-100" :style="{ fontSize: '14px' }"
            >{{ $t("card_refs_status") }}
          </span>
        </div>
        <div
          class="mr-2"
          v-for="ref in refs"
          :key="ref.idt_ref_image"
          :style="{
            backgroundImage: `url(${ref.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '42px',
            height: '42px',
            border: 'none',
            cursor: 'pointer',
            flex: '0 0 42px'
          }"
          @click="
            $route.name === 'Dashboard'
              ? $emit('openpreview', ref.url)
              : openPreview(ref.url)
          "
        ></div>
      </div>
    </div>

    <div class="nav">
      <router-link
        class="btn w-100 text-muted"
        :to="{
          name: type,
          params: {
            model_id: model.idt_model,
            modification_id: modif.idt_model_modif
          }
        }"
        >{{ model.name }}</router-link
      >
    </div>
    <ImagePreview ref="preview" ratio="1x1" />
  </mega-card>
</template>

<script>
import ImagePreview from "../../../components/image-preview";
export default {
  props: ["data"],
  data() {
    return {
      selected: false,
      image: true
    };
  },
  computed: {
    model() {
      return this.data;
    },

    modif() {
      return this.data["modifications"][0] ? this.data["modifications"][0] : [];
    },

    refs() {
      if (this.modif.ref_images) {
        if (this.modif.ref_images.length) {
          return this.modif.ref_images;
        }

        return false;
      }

      return false;
    },

    preview() {
      const mod = this.modif;

      if (mod) {
        if (mod["video"]) {
          this.image = false;
          return mod["video"]["url"];
        } else if (mod["image"]) {
          this.image = true;
          return mod["image"]["url"];
        } else {
          this.image = true;
          return mod["url_icon"];
        }
      }

      return "";
    },

    type() {
      let res = undefined;

      if (this.data.idc_round_type === 2) res = "Image";
      if (this.data.idc_round_type === 3) res = "Video";
      if (this.data.idc_round_type === 4) res = "Model";
      if (this.data.idc_round_type === 5) res = "Gif";
      if (this.data.idc_round_type === 10) res = "Mask";
      if (this.data.idc_round_type === 12) res = "Portal";

      return res;
    }
  },
  methods: {
    toggleActive() {
      let data = {
        idt_model: this.model["idt_model"],
        is_active: !this.model.is_active
      };

      this.$api.v2.put("/model", data).then(() => {
        this.model.is_active = data["is_active"];
        this.$emit("activeToggle");
      });
    },

    openPreview(url) {
      this.$refs.preview.open(url);
    }
  },

  components: {
    ImagePreview
  }
};
</script>
