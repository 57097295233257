var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: _vm.viewBox,
        "aria-labelledby": _vm.iconName,
        role: "presentation"
      }
    },
    [
      _c("title", { attrs: { id: _vm.iconName, lang: "en" } }, [
        _vm._v(_vm._s(_vm.iconName))
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: _vm.iconColor } }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }