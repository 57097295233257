<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    @onClose="modal = false"
  >
    <mega-header :title="$t('new_group')" class="bg-lighter">
      <span class="text-muted">{{ $t("new_group_desc") }}</span>
    </mega-header>

    <div class="card-body">
      <mega-input
        :label="$t('new_group_name')"
        :valid="name.length > 0"
        v-model="name"
      />

      <mega-radio
        :label="$t('group_type')"
        :placeholder="$t('type_holder')"
        :options="roundTypes"
        v-model="round_type"
        :current="round_type"
      />
    </div>

    <div class="card-body">
      <div class="navbar">
        <div class="navbar-group ml-auto">
          <mega-button class="btn-white mr-2" @click="modal = false">{{
            $t("close")
          }}</mega-button>
          <mega-button
            class="btn-primary mr-2"
            :disabled="!name || round_type === undefined"
            @click="submit"
            >{{ $t("save") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
import RoundTypes from "../../data/round_types.json";

export default {
  data() {
    return {
      name: "",
      modal: false,
      round_type: undefined,
      roundTypes: RoundTypes
    };
  },
  mounted() {
    this.roundTypes = RoundTypes.filter(type => type.value > 3);
  },
  methods: {
    submit() {
      let params = {
        name: this.name,
        idc_round_type: this.round_type
      };

      this.$api.v2.post("/group", params).then(response => {
        this.modal = false;
        window.location.reload();
      });
    },

    open(params) {
      this.round_type = undefined;
      this.name = "";

      this.modal = true;

      if (!params) return false;
      if (params.type) this.round_type = params.type;
    }
  }
};
</script>
