var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M17.5 0.5C26.889 0.5 34.5 8.111 34.5 17.5C34.5 26.889 26.889 34.5 17.5 34.5C8.111 34.5 0.5 26.889 0.5 17.5C0.5 8.111 8.111 0.5 17.5 0.5ZM17.25 31.971C25.086 31.971 31.438 25.619 31.438 17.783C31.438 9.946 25.085 3.594 17.25 3.594C9.415 3.594 3.062 9.946 3.062 17.783C3.062 25.619 9.414 31.971 17.25 31.971Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }