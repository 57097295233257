import Vue from "vue";
import Meta from "vue-meta";
import App from "./App.vue";
import { createStore } from "./store";
import { createRouter } from "./router";
import { createLocalization } from "./localization";
import NoSSR from "vue-no-ssr";
import "./scripts/dateProto";
import admins from "./data/admins.json";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

Vue.use(Meta);
Vue.use(Viewer);

Vue.component("no-ssr", NoSSR);

Vue.config.productionTip = false;

export function createApp() {
  const store = createStore();
  const router = createRouter(store);

  const i18n = createLocalization();

  const app = new Vue({
    metaInfo: {
      title: "ARROUND",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "ARROUND Dashboard"
        }
      ],
      htmlAttrs: {
        lang: "en"
      }
    },
    router,
    store,
    i18n,
    async serverPrefetch() {
      if (this.$store.state.token) {
        const data = await this.$store.dispatch("fetchInitial");
        this.$api.setToken(data.token);
      }
    },
    data() {
      return {
        list: [],
        history: []
      };
    },
    async beforeMount() {
      if (this.$store.state.token) {
        this.$api.setToken(this.$store.state.token);
      } else if (!this.$route.meta.guest) {
        this.$router.push({ path: "/login" });
      }

      this.$i18n.locale = localStorage.getItem("language") || "ru";
      this.$store.dispatch("language", this.$i18n.locale);

      let sn = JSON.parse(localStorage.getItem("selectedSn"));
      if (this.$store.state.user) {
        const boundAdmin = admins[process.env.NODE_ENV].find(
          admin => admin.email === this.$store.state.user.email
        );

        this.$store.commit("setIsBoundAdmin", !!boundAdmin);
        if (boundAdmin) {
          sn = (
            await this.$api.v2.get("/sn/sn", {
              params: {
                sn_id: boundAdmin.snId
              }
            })
          ).data;
        }
      }

      this.$store.commit("setSelectedSn", sn);
    },
    render: h => h(App)
  });

  return { app, router, store, i18n };
}
