var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        mouseover: function($event) {
          _vm.mouseOver = true
        },
        mouseout: function($event) {
          _vm.mouseOver = false
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "side-menu" },
        [
          _c(
            "div",
            { staticClass: "menu bg-dark" },
            [
              _c(
                "perfect-scrollbar",
                { staticClass: "scroll-wrap d-flex flex-column" },
                [
                  _c(
                    "div",
                    { staticClass: "menu-header" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "menu-brand",
                          attrs: {
                            to: _vm.$store.state.isBoundAdmin
                              ? { name: "Sn" }
                              : { name: "Sns" }
                          }
                        },
                        [
                          _vm.$store.state.selectedSn
                            ? _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: _vm.$store.state.selectedSn.avatar
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.selectedSn.name)
                                  )
                                ])
                              ])
                            : _c("div", [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/logo-sphere.svg")
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("ARROUND")])
                              ])
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.menu.isMini
                        ? _c("div", { staticClass: "menu-localization mr-2" }, [
                            _vm.language === "en"
                              ? _c("span", { on: { click: _vm.switchLang } }, [
                                  _vm._v("RU")
                                ])
                              : _c("span", { on: { click: _vm.switchLang } }, [
                                  _vm._v("EN")
                                ])
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass: "menu-nav",
                      on: { click: _vm.menu.open_toggle }
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "d-flex align-items-center mt-4" },
                        [
                          _c(
                            "mega-menu-link",
                            {
                              staticClass: "w-100",
                              attrs: {
                                to: { name: "Dashboard" },
                                old_icon: "",
                                icon: "i-home",
                                exact: ""
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("dashboard")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: { name: "Sns" },
                                    old_icon: "",
                                    icon: "i-grid"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("social_networks")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "mega-menu-link",
                            {
                              staticClass: "w-100",
                              attrs: {
                                to: { name: "Users" },
                                old_icon: "",
                                icon: "i-users"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("users")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: { name: "Businesses" },
                                    old_icon: "",
                                    icon: "i-globe"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("businesses")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "mega-menu-link",
                            {
                              staticClass: "w-100",
                              attrs: {
                                to: "/rounds/normal",
                                old_icon: "",
                                icon: "i-layers"
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("rounds")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.$store.state.isBoundAdmin
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  attrs: { to: { name: "Round_Post" } }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "mega-menu-link",
                            {
                              staticClass: "w-100",
                              attrs: { to: "/rounds/long" }
                            },
                            [
                              _c("IconBase", [_c("SideVideo")], 1),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("long_videos")) +
                                  "\n            "
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.$store.state.isBoundAdmin
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  attrs: {
                                    to: { name: "Round_Post_LongVideo" }
                                  }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: { to: "/streams" }
                                },
                                [
                                  _c(
                                    "IconBase",
                                    {
                                      attrs: { viewBox: "0 0 23 23", width: 19 }
                                    },
                                    [_c("Streams")],
                                    1
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("streams")) +
                                      "\n            "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "mega-menu-link",
                            {
                              staticClass: "w-100",
                              attrs: { to: "/channels" }
                            },
                            [
                              _c(
                                "IconBase",
                                { attrs: { viewBox: "0 0 22 22", width: 19 } },
                                [_c("Channels")],
                                1
                              ),
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("channels")) +
                                  "\n            "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: {
                                      name: "Models",
                                      params: {
                                        roundType: "3d",
                                        is_active: "active"
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("IconBase", [_c("Side3D")], 1),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("models")) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  on: { click: _vm.addModel }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: {
                                      name: "Models",
                                      params: {
                                        roundType: "gif",
                                        is_active: "active"
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("IconBase", [_c("SideGif")], 1),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("gifs")) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  on: { click: _vm.addGif }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: {
                                      name: "Models",
                                      params: {
                                        roundType: "video",
                                        is_active: "active"
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("IconBase", [_c("SideVideo")], 1),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("videos")) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  on: { click: _vm.addVideo }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: {
                                      name: "Models",
                                      params: {
                                        roundType: "image",
                                        is_active: "active"
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("IconBase", [_c("SideImage")], 1),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("images")) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  on: { click: _vm.addImage }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: {
                                      name: "Models",
                                      params: {
                                        roundType: "mask",
                                        is_active: "active"
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("IconBase", [_c("SideMask")], 1),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("masks")) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  on: { click: _vm.addMask }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: {
                                      name: "Models",
                                      params: {
                                        roundType: "portal",
                                        is_active: "active"
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("IconBase", [_c("SidePortal")], 1),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("portals")) +
                                      "\n            "
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "btn mr-2 p-0 btn-dark btn-xs",
                                  on: { click: _vm.addPortal }
                                },
                                [_c("i", { staticClass: "i-plus" })]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: { to: { name: "Statistics" } }
                                },
                                [
                                  _c(
                                    "IconBase",
                                    {
                                      staticStyle: {
                                        "margin-left": "2px",
                                        "margin-right": "13px",
                                        "margin-top": "3px"
                                      },
                                      attrs: { viewbox: "0 0 45 45", width: 20 }
                                    },
                                    [_c("Statistics")],
                                    1
                                  ),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("statistics")) +
                                      "\n            "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$store.state.isBoundAdmin
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: { name: "Moderation" },
                                    icon: "i-help-circle",
                                    old_icon: "",
                                    exact: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("moderation")) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.$store.state.unreadMessages,
                                      expression: "$store.state.unreadMessages"
                                    }
                                  ],
                                  staticClass:
                                    "btn mr-2 p-0 btn-primary btn-xs",
                                  attrs: { to: { name: "Support" } }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.unreadMessages)
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$store.state.isBoundAdmin &&
                      _vm.SUPPORT_HAVING_SNS.includes(
                        _vm.$store.state.selectedSn.id
                      )
                        ? _c(
                            "li",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "mega-menu-link",
                                {
                                  staticClass: "w-100",
                                  attrs: {
                                    to: { name: "Support" },
                                    icon: "i-help-circle",
                                    old_icon: "",
                                    exact: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("care_service")) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.$store.state.unreadMessages,
                                      expression: "$store.state.unreadMessages"
                                    }
                                  ],
                                  staticClass:
                                    "btn mr-2 p-0 btn-primary btn-xs",
                                  attrs: { to: { name: "Support" } }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$store.state.unreadMessages)
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("ul", { staticClass: "menu-nav mt-auto" }, [
                    _c("li", { staticClass: "menu-divider" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.user.email) +
                          "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "li",
                      { staticClass: "nav-item" },
                      [
                        _c(
                          "mega-menu-link",
                          {
                            staticClass: "w-100",
                            attrs: {
                              to: { name: "Options" },
                              icon: "i-settings"
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("options")) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "nav-link",
                            on: { click: _vm.logOut }
                          },
                          [
                            _c("i", { staticClass: "i-power float-left" }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm.$t("logout")))])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.mouseOver,
                      expression: "mouseOver"
                    }
                  ],
                  staticClass: "menu-collapse",
                  on: { click: _vm.menu.minimize_toggle }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "p-1 d-flex align-items-center justify-content-center menu-collapse-btn"
                    },
                    [
                      _c("i", {
                        class: _vm.menu.isMini
                          ? "i-arrow-right"
                          : "i-arrow-left"
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "menu-collapse-border" })
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("new-model", { ref: "new-model" })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }