var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navbar navbar-page py-3 sticky-top bg-white navbar-shadow"
    },
    [
      _c("div", { staticClass: "container-fluid" }, [
        _vm.page
          ? _c("span", { staticClass: "btn events-disable" }, [
              _vm._v(" " + _vm._s(_vm.page) + " ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "btn bg-light p-0 ml-auto lg-up:d-none",
            on: { click: _vm.menu.open_toggle }
          },
          [_c("i", { staticClass: "i-menu-simple" })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }