<template>
  <div
    class="admin-page bg-lighter"
    v-if="token"
    :class="{ 'side-menu-mini': menu.isMini, 'side-menu-open': menu.isOpen }"
  >
    <page-sidebar />

    <div
      class="admin-body d-flex flex-column"
      :class="{ maxHeight: $route.name == 'Channels' }"
      style="min-height: 100vh"
    >
      <page-navbar />

      <div style="flex: 1" :class="{ parentHeight: $route.name == 'Channels' }">
        <no-ssr>
          <keep-alive :include="['UsersPage', 'RoundsPage']">
            <router-view
              v-if="!$store.state.isBoundAdmin || $store.state.selectedSn"
            />
            <mega-loading
              v-else
              class="d-flex h-100 w-100 align-items-center justify-content-center"
            ></mega-loading>
          </keep-alive>
        </no-ssr>
      </div>

      <page-footer />
    </div>
  </div>
</template>

<script>
import PageFooter from "../components/footer";
import PageSidebar from "../components/side-menu";
import PageNavbar from "../components/navbar";

export default {
  name: "App",
  data() {
    return {
      sidebar: false
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    menu() {
      if (process.browser) return this.$mega.menu;

      return {
        isMini: false,
        isOpen: false
      };
    }
  },
  mounted() {
    this.$store.dispatch("openMsgSocket");
  },
  methods: {},
  components: {
    PageNavbar,
    PageFooter,
    PageSidebar
  }
};
</script>

<style lang="sass" scoped>
.maxHeight
  height: 100vh !important
.parentHeight
  height: calc(100% - 125px)
</style>
