var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dashboard-page" },
    [
      _c(
        "mega-header",
        {
          staticClass: "overlay-dark overlay-90 text-lighter",
          attrs: {
            title: _vm.$t("dashboard"),
            background:
              "https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          }
        },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(
              _vm._s(_vm.$t("welcome")) + " " + _vm._s(_vm.user.email) + "!"
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top py-3 px-6 sm-down:flex-column justify-content-between",
          staticStyle: { top: "70px" }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { gap: "12px" } },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-primary md-up text-left d-block sm-down:w-100 sm-down:mb-3",
                  attrs: { to: { name: "Round_Post" } }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("new_round")))])]
              ),
              _vm._v(" "),
              !_vm.$store.state.isBoundAdmin
                ? _c(
                    "span",
                    {
                      staticClass:
                        "btn btn-sm btn-arround md-up text-left d-block sm-down:w-100 sm-down:mb-3",
                      on: { click: _vm.addModel }
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("new_model")))])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "btn btn-sm btn-arround md-up text-left d-block sm-down:w-100 sm-down:mb-3",
                  on: { click: _vm.addGroup }
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("new_group")))])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { gap: "12px" } },
            [
              !_vm.$store.state.isBoundAdmin
                ? _c(
                    "router-link",
                    {
                      staticClass:
                        "btn btn-sm btn-light text-left d-block sm-down:w-100",
                      attrs: {
                        to: {
                          name: "Models",
                          params: { roundType: "3d", is_active: "active" }
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "i-package float-left" }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("models")))])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "btn btn-sm btn-light text-left d-block sm-down:w-100",
                  attrs: { to: { name: "Rounds", params: { type: "normal" } } }
                },
                [
                  _c("i", { staticClass: "i-layers float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("rounds")))])
                ]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container pb-5 mt-3" },
          [
            _c(
              "div",
              { staticClass: "d-flex mb-4" },
              [
                _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
                  _c("i", { staticClass: "i-heart" }),
                  _vm._v(" " + _vm._s(_vm.$t("popular_rounds")) + "\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm ml-auto",
                    attrs: {
                      to: { name: "Rounds", params: { type: "normal" } }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("view_all")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "no-ssr",
              [
                _c(
                  "mega-slider",
                  { staticClass: "row", attrs: { options: _vm.slider } },
                  _vm._l(_vm.popularRounds, function(round, i) {
                    return _c("mega-slide", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c("round-card", {
                            staticClass: "cursor-grab",
                            attrs: { data: round }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container pb-5 mt-3" },
          [
            _c(
              "div",
              { staticClass: "d-flex mb-4" },
              [
                _c(
                  "h4",
                  { staticClass: "bold text-muted text-dark mb-0" },
                  [
                    _c(
                      "IconBase",
                      {
                        staticStyle: { "margin-right": "0px" },
                        attrs: { viewBox: "0 0 800 800", width: 20 }
                      },
                      [_c("Promoted")],
                      1
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("rounds_promoted")) +
                        "\n        "
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm ml-auto",
                    attrs: {
                      to: { name: "Rounds", params: { type: "normal" } }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("view_all")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "no-ssr",
              [
                _c(
                  "mega-slider",
                  { staticClass: "row", attrs: { options: _vm.slider } },
                  _vm._l(_vm.promotedRounds, function(round, i) {
                    return _c("mega-slide", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c("round-card", {
                            staticClass: "cursor-grab",
                            attrs: { data: round }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("section", [
        _c(
          "div",
          { staticClass: "container pb-5 mt-3" },
          [
            _c(
              "div",
              { staticClass: "d-flex mb-4" },
              [
                _c("h4", { staticClass: "bold text-muted text-dark mb-0" }, [
                  _c("i", { staticClass: "i-users" }),
                  _vm._v(" " + _vm._s(_vm.$t("popular_users")) + "\n        ")
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-sm ml-auto",
                    attrs: { to: { name: "Users" } }
                  },
                  [_vm._v(_vm._s(_vm.$t("view_all")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "no-ssr",
              [
                _c(
                  "mega-modal",
                  {
                    ref: "qr",
                    staticClass: "modal-sm",
                    attrs: { active: _vm.modal, center: true },
                    on: {
                      onClose: function($event) {
                        _vm.modal = false
                      },
                      click: function($event) {
                        _vm.modal = !_vm.modal
                      }
                    }
                  },
                  [
                    _c("qr-code", {
                      attrs: {
                        uri:
                          "type=prof&id=" +
                          (_vm.idt_user ? String(_vm.idt_user) : "")
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "mega-slider",
                  {
                    staticClass: "row",
                    attrs: {
                      options: {
                        slidesPerView: 4,
                        breakpoints: {
                          990: { slidesPerView: 2 },
                          1470: { slidesPerView: 3 }
                        }
                      }
                    }
                  },
                  _vm._l(_vm.popularUsers, function(user, i) {
                    return _c("mega-slide", { key: i }, [
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c("user-card", {
                            staticClass: "cursor-grab",
                            attrs: { data: user },
                            on: { "open-qr": _vm.openQR }
                          })
                        ],
                        1
                      )
                    ])
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      !_vm.$store.state.isBoundAdmin
        ? _c("section", [
            _c(
              "div",
              { staticClass: "container pb-5" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mb-4" },
                  [
                    _c(
                      "h4",
                      { staticClass: "bold text-muted text-dark mb-0" },
                      [
                        _c(
                          "IconBase",
                          {
                            staticStyle: { "margin-right": "0px" },
                            attrs: { viewBox: "0 0 45 45", width: 20 }
                          },
                          [_c("Side3D")],
                          1
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("popular_models")) +
                            "\n        "
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm ml-auto",
                        attrs: {
                          to: {
                            name: "Models",
                            params: { roundType: "3d", is_active: "active" }
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("view_all")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("no-ssr", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "row d-flex justify-content-around position-relative"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sm-down:col-12 md-up:col-5 mx-2",
                          staticStyle: { "flex-direction": "column" }
                        },
                        [
                          _c("ImagePreview", {
                            ref: "preview",
                            attrs: { ratio: "1x1" }
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(" iOS ")]),
                          _vm._v(" "),
                          _c(
                            "mega-slider",
                            {
                              staticClass: "row rounded border pt-2",
                              attrs: {
                                options: {
                                  slidesPerView: 3,
                                  breakpoints: {
                                    990: { slidesPerView: 2 },
                                    1470: { slidesPerView: 3 }
                                  }
                                }
                              }
                            },
                            _vm._l(_vm.modelsIOS, function(model, i) {
                              return _c("mega-slide", { key: i }, [
                                _c(
                                  "div",
                                  { staticClass: "px-3" },
                                  [
                                    _c("model-card", {
                                      staticClass: "cursor-grab",
                                      attrs: { data: model },
                                      on: { openpreview: _vm.openPreview }
                                    })
                                  ],
                                  1
                                )
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "sm-down:col-12 md-up:col-5 mx-2",
                          staticStyle: { "flex-direction": "column" }
                        },
                        [
                          _c("span", [_vm._v(" Android ")]),
                          _vm._v(" "),
                          _c(
                            "mega-slider",
                            {
                              staticClass: "row rounded border pt-2",
                              attrs: {
                                options: {
                                  slidesPerView: 3,
                                  breakpoints: {
                                    990: { slidesPerView: 2 },
                                    1470: { slidesPerView: 3 }
                                  }
                                }
                              }
                            },
                            _vm._l(_vm.modelsAndroid, function(model, i) {
                              return _c("mega-slide", { key: i }, [
                                _c(
                                  "div",
                                  { staticClass: "px-3" },
                                  [
                                    _c("model-card", {
                                      staticClass: "cursor-grab",
                                      attrs: { data: model },
                                      on: { openpreview: _vm.openPreview }
                                    })
                                  ],
                                  1
                                )
                              ])
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.$store.state.isBoundAdmin
        ? _c("section", [
            _c(
              "div",
              { staticClass: "container pb-5" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex mb-4" },
                  [
                    _c(
                      "h4",
                      { staticClass: "bold text-muted text-dark mb-0" },
                      [
                        _c("i", { staticClass: "i-globe" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("popular_businesses")) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-sm ml-auto",
                        attrs: {
                          to: {
                            name: "Businesses"
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("view_all")))]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "no-ssr",
                  [
                    _c(
                      "mega-modal",
                      {
                        ref: "qr-business",
                        staticClass: "modal-sm",
                        attrs: { active: _vm.modal_arr, center: true },
                        on: {
                          onClose: function($event) {
                            _vm.modal_arr = false
                          },
                          click: function($event) {
                            _vm.modal_arr = !_vm.modal_arr
                          }
                        }
                      },
                      [
                        _c("qr-code", {
                          attrs: {
                            uri:
                              "type=arr&id=" +
                              (_vm.idt_arr ? String(_vm.idt_arr) : "")
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "mega-slider",
                      {
                        staticClass: "row",
                        attrs: {
                          options: {
                            slidesPerView: 4,
                            breakpoints: {
                              990: { slidesPerView: 2 },
                              1470: { slidesPerView: 3 }
                            }
                          }
                        }
                      },
                      _vm._l(_vm.popularBusinesses, function(business, i) {
                        return _c("mega-slide", { key: i }, [
                          _c(
                            "div",
                            { staticClass: "px-1" },
                            [
                              _c("business-card", {
                                staticClass: "cursor-grab",
                                attrs: { arr: business },
                                on: { "open-qr": _vm.openQrBusiness }
                              })
                            ],
                            1
                          )
                        ])
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("new-group", { ref: "new-group" }),
      _vm._v(" "),
      _c("new-model", { ref: "new-model" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }