var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-modal",
    {
      ref: "modal-2",
      staticClass: "modal-sm",
      attrs: { active: _vm.modal },
      on: {
        onClose: function($event) {
          _vm.modal = false
        }
      }
    },
    [
      _c(
        "mega-header",
        { staticClass: "bg-lighter", attrs: { title: _vm.$t("new_model") } },
        [
          _c("span", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.$t("new_model_desc")))
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body pb-0" },
        [
          _c("mega-input", {
            attrs: {
              label: _vm.$t("new_model_name"),
              valid: _vm.name.length > 0
            },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = $$v
              },
              expression: "name"
            }
          }),
          _vm._v(" "),
          _c("mega-radio", {
            attrs: {
              label: _vm.$t("round_type"),
              options: _vm.roundTypes,
              current: _vm.round_type
            },
            on: {
              dropgrp: function($event) {
                _vm.group = 0
              }
            },
            model: {
              value: _vm.round_type,
              callback: function($$v) {
                _vm.round_type = $$v
              },
              expression: "round_type"
            }
          }),
          _vm._v(" "),
          _vm.round_type > 3
            ? _c("group-select", {
                attrs: {
                  label: _vm.$t("group"),
                  type: _vm.round_type,
                  placeholder: _vm.$t("group_holder")
                },
                model: {
                  value: _vm.group,
                  callback: function($$v) {
                    _vm.group = $$v
                  },
                  expression: "group"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "navbar" }, [
          _c(
            "div",
            { staticClass: "navbar-group ml-auto" },
            [
              _c(
                "mega-button",
                {
                  staticClass: "btn-white mr-2",
                  on: {
                    click: function($event) {
                      _vm.modal = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("close")))]
              ),
              _vm._v(" "),
              _c(
                "mega-button",
                {
                  staticClass: "btn-primary mr-2",
                  attrs: {
                    disabled: !_vm.name || _vm.round_type === undefined
                  },
                  on: { click: _vm.submit }
                },
                [_vm._v(_vm._s(_vm.$t("save")))]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }