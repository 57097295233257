<template>
  <div class="dashboard-page">
    <mega-header
      class="overlay-dark overlay-90 text-lighter"
      :title="$t('dashboard')"
      background="https://images.pexels.com/photos/1902647/pexels-photo-1902647.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    >
      <span class="text-muted">{{ $t("welcome") }} {{ user.email }}!</span>
    </mega-header>

    <div
      class="navbar sm-down:d-none border-bottom bg-lighter md-up:sticky-top py-3 px-6 sm-down:flex-column justify-content-between"
      style="top: 70px;"
    >
      <div class="d-flex" style="gap: 12px">
        <router-link
          :to="{ name: 'Round_Post' }"
          class="btn btn-sm btn-primary md-up text-left d-block sm-down:w-100 sm-down:mb-3"
        >
          <span>{{ $t("new_round") }}</span>
        </router-link>

        <span
          v-if="!$store.state.isBoundAdmin"
          class="btn btn-sm btn-arround md-up text-left d-block sm-down:w-100 sm-down:mb-3"
          @click="addModel"
        >
          <span>{{ $t("new_model") }}</span>
        </span>

        <span
          class="btn btn-sm btn-arround md-up text-left d-block sm-down:w-100 sm-down:mb-3"
          @click="addGroup"
        >
          <span>{{ $t("new_group") }}</span>
        </span>
      </div>

      <div class="d-flex" style="gap: 12px">
        <router-link
          v-if="!$store.state.isBoundAdmin"
          :to="{
            name: 'Models',
            params: { roundType: '3d', is_active: 'active' }
          }"
          class="btn btn-sm btn-light text-left d-block sm-down:w-100"
        >
          <i class="i-package float-left"></i>
          <span>{{ $t("models") }}</span>
        </router-link>

        <router-link
          :to="{ name: 'Rounds', params: { type: 'normal' } }"
          class="btn btn-sm btn-light text-left d-block sm-down:w-100"
        >
          <i class="i-layers float-left"></i>
          <span>{{ $t("rounds") }}</span>
        </router-link>
      </div>
    </div>

    <section>
      <div class="container pb-5 mt-3">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            <i class="i-heart"></i> {{ $t("popular_rounds") }}
          </h4>
          <router-link
            class="btn btn-sm ml-auto"
            :to="{ name: 'Rounds', params: { type: 'normal' } }"
            >{{ $t("view_all") }}</router-link
          >
        </div>

        <no-ssr>
          <mega-slider class="row" :options="slider">
            <mega-slide v-for="(round, i) in popularRounds" :key="i">
              <div class="px-3">
                <round-card class="cursor-grab" :data="round" />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section>

    <section>
      <div class="container pb-5 mt-3">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            <IconBase
              :viewBox="'0 0 800 800'"
              :width="20"
              style="margin-right: 0px"
              ><Promoted
            /></IconBase>
            {{ $t("rounds_promoted") }}
          </h4>
          <router-link
            class="btn btn-sm ml-auto"
            :to="{ name: 'Rounds', params: { type: 'normal' } }"
            >{{ $t("view_all") }}</router-link
          >
        </div>

        <no-ssr>
          <mega-slider class="row" :options="slider">
            <mega-slide v-for="(round, i) in promotedRounds" :key="i">
              <div class="px-3">
                <round-card class="cursor-grab" :data="round" />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section>

    <section>
      <div class="container pb-5 mt-3">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            <i class="i-users"></i> {{ $t("popular_users") }}
          </h4>
          <router-link class="btn btn-sm ml-auto" :to="{ name: 'Users' }">{{
            $t("view_all")
          }}</router-link>
        </div>

        <no-ssr>
          <mega-modal
            ref="qr"
            class="modal-sm"
            :active="modal"
            :center="true"
            @onClose="modal = false"
            @click="modal = !modal"
          >
            <qr-code
              :uri="'type=prof&id=' + (idt_user ? String(idt_user) : '')"
            />
          </mega-modal>
          <mega-slider
            class="row"
            :options="{
              slidesPerView: 4,
              breakpoints: {
                990: { slidesPerView: 2 },
                1470: { slidesPerView: 3 }
              }
            }"
          >
            <mega-slide v-for="(user, i) in popularUsers" :key="i">
              <div class="px-3">
                <user-card class="cursor-grab" :data="user" @open-qr="openQR" />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section>

    <section v-if="!$store.state.isBoundAdmin">
      <div class="container pb-5">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            <IconBase
              :viewBox="'0 0 45 45'"
              :width="20"
              style="margin-right: 0px"
              ><Side3D
            /></IconBase>
            {{ $t("popular_models") }}
          </h4>
          <router-link
            class="btn btn-sm ml-auto"
            :to="{
              name: 'Models',
              params: { roundType: '3d', is_active: 'active' }
            }"
            >{{ $t("view_all") }}</router-link
          >
        </div>

        <no-ssr>
          <div class="row d-flex justify-content-around position-relative">
            <div
              class="sm-down:col-12 md-up:col-5 mx-2"
              style="flex-direction: column;"
            >
              <ImagePreview ref="preview" ratio="1x1" />
              <span> iOS </span>
              <mega-slider
                class="row rounded border pt-2"
                :options="{
                  slidesPerView: 3,
                  breakpoints: {
                    990: { slidesPerView: 2 },
                    1470: { slidesPerView: 3 }
                  }
                }"
              >
                <mega-slide v-for="(model, i) in modelsIOS" :key="i">
                  <div class="px-3">
                    <model-card
                      class="cursor-grab"
                      :data="model"
                      @openpreview="openPreview"
                    />
                  </div>
                </mega-slide>
              </mega-slider>
            </div>
            <div
              class="sm-down:col-12 md-up:col-5 mx-2"
              style="flex-direction: column;"
            >
              <span> Android </span>
              <mega-slider
                class="row rounded border pt-2"
                :options="{
                  slidesPerView: 3,
                  breakpoints: {
                    990: { slidesPerView: 2 },
                    1470: { slidesPerView: 3 }
                  }
                }"
              >
                <mega-slide v-for="(model, i) in modelsAndroid" :key="i">
                  <div class="px-3">
                    <model-card
                      class="cursor-grab"
                      :data="model"
                      @openpreview="openPreview"
                    />
                  </div>
                </mega-slide>
              </mega-slider>
            </div>
          </div>
        </no-ssr>
      </div>
    </section>

    <section v-if="!$store.state.isBoundAdmin">
      <div class="container pb-5">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            <i class="i-globe"></i>
            {{ $t("popular_businesses") }}
          </h4>
          <router-link
            class="btn btn-sm ml-auto"
            :to="{
              name: 'Businesses'
            }"
            >{{ $t("view_all") }}</router-link
          >
        </div>

        <no-ssr>
          <mega-modal
            ref="qr-business"
            class="modal-sm"
            :active="modal_arr"
            :center="true"
            @onClose="modal_arr = false"
            @click="modal_arr = !modal_arr"
          >
            <qr-code :uri="'type=arr&id=' + (idt_arr ? String(idt_arr) : '')" />
          </mega-modal>
          <mega-slider
            class="row"
            :options="{
              slidesPerView: 4,
              breakpoints: {
                990: { slidesPerView: 2 },
                1470: { slidesPerView: 3 }
              }
            }"
          >
            <mega-slide v-for="(business, i) in popularBusinesses" :key="i">
              <div class="px-1">
                <business-card
                  class="cursor-grab"
                  :arr="business"
                  @open-qr="openQrBusiness"
                />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section>

    <!-- <section>
      <div class="container pb-5">
        <div class="d-flex mb-4">
          <h4 class="bold text-muted text-dark mb-0">
            <IconBase
              :viewBox="'0 0 45 45'"
              :width="20"
              style="margin-right: 0px"
              ><SideImage
            /></IconBase>
            {{ $t("popular_images") }}
          </h4>
          <router-link
            class="btn btn-sm ml-auto"
            :to="{
              name: 'Models',
              params: { roundType: 'image', is_active: 'active' }
            }"
            >{{ $t("view_all") }}</router-link
          >
        </div>

        <no-ssr>
          <mega-slider class="row" :options="slider">
            <mega-slide v-for="(image, i) in images" :key="i">
              <div class="px-1">
                <model-card class="cursor-grab" :data="image" />
              </div>
            </mega-slide>
          </mega-slider>
        </no-ssr>
      </div>
    </section> -->

    <new-group ref="new-group" />
    <new-model ref="new-model" />
  </div>
</template>

<script>
import NewGroup from "../../components/new_group";
import NewModel from "../../components/new_model";
import ModelCard from "../Models/components/model-card";
import RoundCard from "../Rounds/components/round-card";
import UserCard from "../Users/components/user-card";
import BusinessCard from "../Businesses/components/business-card";
import IconBase from "../../../components/icons/IconBase";
import Promoted from "../../../components/icons/set/Promoted";
import Side3D from "../../../components/icons/set/Side3D";
import ImagePreview from "../../components/image-preview";
import QrCode from "../../components/QrCode";

export default {
  name: "Dashboard",
  data() {
    const language = localStorage.getItem("language") || "en";

    return {
      baseChartOptions: {
        chart: {
          height: "300px",
          selection: { enabled: false },
          zoom: { enabled: false },
          toolbar: { show: false }
        }
      },
      locale: language == "ru" ? "ru-RU" : "en-US",
      slider: {
        slidesPerView: 5,
        breakpoints: {
          750: { slidesPerView: 1 },
          990: { slidesPerView: 2 },
          1470: { slidesPerView: 3 }
        }
      },
      models: [],
      popularRounds: [],
      promotedRounds: [],
      recommendedRounds: [],
      popularUsers: [],
      modelsIOS: [],
      modelsAndroid: [],
      modal: false,
      idt_user: "",
      idt_arr: "",
      modal_arr: false,
      // images: [],
      popularBusinesses: [],
      popularRefs: [],
      refresh: null,
      year: this.$u2d(new Date(), "YYYY")
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    this.render();
    this.$navbar.name = this.$t("dashboard");
  },

  methods: {
    openPreview(url) {
      this.$refs["preview"].open(url);
    },
    openQR(id) {
      this.idt_user = id;
      this.modal = !this.modal;
    },
    openQrBusiness(id) {
      this.idt_arr = id;
      this.modal_arr = !this.modal_arr;
    },
    addModel() {
      this.$refs["new-model"].open();
    },
    addGroup() {
      this.$refs["new-group"].open();
    },

    render() {
      this.$nextTick(() => {
        setTimeout(() => {
          Promise.all([
            this.getPopularRounds(),
            this.getPromotedRounds(),
            this.getPopularUsers(),
            this.getModels(),
            this.getPopularBusinesses()
            // this.getImages()
          ]);
        }, 0);
      });
    },

    getModels() {
      this.$api.v2base
        .get("/model/top", { params: { device: "ios" } })
        .then(response => {
          let models = response.data;
          this.modelsIOS = models.slice(0, 6);
        });
      this.$api.v2base
        .get("/model/top", { params: { device: "android" } })
        .then(response => {
          let models = response.data;
          this.modelsAndroid = models.slice(0, 6);
        });
    },

    // getImages() {
    //   this.$api.v2base
    //     .get("/user/refImages", { params: { lat: 59.9, lng: 30.3, limit: 6 } })
    //     .then(response => {
    //       let images = response.data["ref_images"];
    //       this.images = images.slice(0, 6);
    //     });
    // },

    getPopularRounds() {
      this.$api.v2base
        .get("/round/top", {
          params: {
            limit: 6,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(response => {
          const rounds = response.data;

          this.popularRounds = rounds.slice(0, 6);
        });
    },

    getPromotedRounds() {
      this.$api.v2base
        .get("/round/promoted", {
          params: {
            limit: 6,
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(response => {
          const rounds = response.data;

          this.promotedRounds = rounds.slice(0, 6);
        });
    },

    getPopularUsers() {
      this.$api.v2base
        .get("/user/top", {
          params: {
            sn_id: this.$store.getters.selectedSnId
          }
        })
        .then(response => {
          const users = response.data;
          this.popularUsers = users;
        });
    },

    getPopularBusinesses() {
      this.$api.v2base.get("/arp/top").then(response => {
        const businesses = response.data;
        this.popularBusinesses = businesses;
      });
    }
  },
  components: {
    NewGroup,
    NewModel,
    RoundCard,
    ModelCard,
    UserCard,
    BusinessCard,
    IconBase,
    Promoted,
    Side3D,
    ImagePreview,
    QrCode
  }
};
</script>

<style lang="sass" scoped>
.swiper-wrapper
  position: initial
</style>
