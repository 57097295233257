import Moment from "moment";

let u2d = function(timestamp, str) {
  Moment.locale(localStorage.getItem("language") || "ru");

  let date = undefined;

  function getTimezoneOffset() {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    let offset = new Date().getTimezoneOffset();
    let sign = offset < 0 ? "+" : "-";
    offset = Math.abs(offset);
    return sign + z((offset / 60) | 0) + z(offset % 60);
  }

  if (!timestamp) return "";
  if (timestamp instanceof Date) date = timestamp;

  if (!date)
    return Moment.unix(timestamp)
      .utcOffset(getTimezoneOffset())
      .format(str);

  return Moment(date).format(str);
};

export default {
  install: function(Vue) {
    Vue.filter("u2d", u2d);

    Vue.prototype.$u2d = u2d;
  }
};
