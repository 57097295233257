<template>
  <div @mouseover="mouseOver = true" @mouseout="mouseOver = false">
    <div class="side-menu">
      <div class="menu bg-dark">
        <perfect-scrollbar class="scroll-wrap d-flex flex-column">
          <div class="menu-header">
            <router-link
              :to="$store.state.isBoundAdmin ? { name: 'Sn' } : { name: 'Sns' }"
              class="menu-brand"
            >
              <div v-if="$store.state.selectedSn">
                <img :src="$store.state.selectedSn.avatar" />
                <span>{{ $store.state.selectedSn.name }}</span>
              </div>
              <div v-else>
                <img :src="require('../../assets/logo-sphere.svg')" />
                <span>ARROUND</span>
              </div>
            </router-link>

            <div class="menu-localization mr-2" v-if="!menu.isMini">
              <span @click="switchLang" v-if="language === 'en'">RU</span>
              <span @click="switchLang" v-else>EN</span>
            </div>
          </div>

          <ul class="menu-nav" @click="menu.open_toggle">
            <li class="d-flex align-items-center mt-4">
              <mega-menu-link
                class="w-100"
                :to="{ name: 'Dashboard' }"
                old_icon
                icon="i-home"
                exact
              >
                {{ $t("dashboard") }}
              </mega-menu-link>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{ name: 'Sns' }"
                old_icon
                icon="i-grid"
              >
                {{ $t("social_networks") }}
              </mega-menu-link>
            </li>

            <li class="d-flex align-items-center">
              <mega-menu-link
                :to="{ name: 'Users' }"
                old_icon
                icon="i-users"
                class="w-100"
              >
                {{ $t("users") }}
              </mega-menu-link>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{ name: 'Businesses' }"
                old_icon
                icon="i-globe"
              >
                {{ $t("businesses") }}
              </mega-menu-link>
            </li>

            <li class="d-flex align-items-center">
              <mega-menu-link
                class="w-100"
                to="/rounds/normal"
                old_icon
                icon="i-layers"
              >
                {{ $t("rounds") }}
              </mega-menu-link>

              <router-link
                v-if="!$store.state.isBoundAdmin"
                :to="{ name: 'Round_Post' }"
                class="btn mr-2 p-0 btn-dark btn-xs"
              >
                <i class="i-plus"></i>
              </router-link>
            </li>

            <li class="d-flex align-items-center">
              <mega-menu-link class="w-100" to="/rounds/long">
                <IconBase><SideVideo /></IconBase>
                {{ $t("long_videos") }}
              </mega-menu-link>

              <router-link
                v-if="!$store.state.isBoundAdmin"
                :to="{ name: 'Round_Post_LongVideo' }"
                class="btn mr-2 p-0 btn-dark btn-xs"
              >
                <i class="i-plus"></i>
              </router-link>
            </li>

            <li v-if="!$store.state.isBoundAdmin">
              <mega-menu-link class="w-100" to="/streams">
                <IconBase :viewBox="'0 0 23 23'" :width="19"
                  ><Streams
                /></IconBase>
                {{ $t("streams") }}
              </mega-menu-link>
            </li>

            <li>
              <mega-menu-link class="w-100" to="/channels">
                <IconBase :viewBox="'0 0 22 22'" :width="19"
                  ><Channels
                /></IconBase>
                {{ $t("channels") }}
              </mega-menu-link>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{
                  name: 'Models',
                  params: { roundType: '3d', is_active: 'active' }
                }"
              >
                <IconBase><Side3D /></IconBase>
                {{ $t("models") }}
              </mega-menu-link>
              <span @click="addModel" class="btn mr-2 p-0 btn-dark btn-xs">
                <i class="i-plus"></i>
              </span>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{
                  name: 'Models',
                  params: { roundType: 'gif', is_active: 'active' }
                }"
              >
                <IconBase><SideGif /></IconBase>
                {{ $t("gifs") }}
              </mega-menu-link>
              <span @click="addGif" class="btn mr-2 p-0 btn-dark btn-xs">
                <i class="i-plus"></i>
              </span>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{
                  name: 'Models',
                  params: { roundType: 'video', is_active: 'active' }
                }"
              >
                <IconBase><SideVideo /></IconBase>
                {{ $t("videos") }}
              </mega-menu-link>
              <span @click="addVideo" class="btn mr-2 p-0 btn-dark btn-xs">
                <i class="i-plus"></i>
              </span>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{
                  name: 'Models',
                  params: { roundType: 'image', is_active: 'active' }
                }"
              >
                <IconBase><SideImage /></IconBase>
                {{ $t("images") }}
              </mega-menu-link>
              <span @click="addImage" class="btn mr-2 p-0 btn-dark btn-xs">
                <i class="i-plus"></i>
              </span>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{
                  name: 'Models',
                  params: { roundType: 'mask', is_active: 'active' }
                }"
              >
                <IconBase><SideMask /></IconBase>
                {{ $t("masks") }}
              </mega-menu-link>
              <span @click="addMask" class="btn mr-2 p-0 btn-dark btn-xs">
                <i class="i-plus"></i>
              </span>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{
                  name: 'Models',
                  params: { roundType: 'portal', is_active: 'active' }
                }"
              >
                <IconBase><SidePortal /></IconBase>
                {{ $t("portals") }}
              </mega-menu-link>
              <span @click="addPortal" class="btn mr-2 p-0 btn-dark btn-xs">
                <i class="i-plus"></i>
              </span>
            </li>

            <!-- <li class="d-flex align-items-center">
              <mega-menu-link class="w-100" :to="{ name: 'Payouts' }">
                <IconBase
                  style="margin-left: 2px; margin-right: 13px; margin-top: 3px"
                  :viewbox="'0 0 45 45'"
                  :width="20"
                  ><SideMoney
                /></IconBase>
                {{ $t("payouts") }}
              </mega-menu-link>
            </li> -->

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link class="w-100" :to="{ name: 'Statistics' }">
                <IconBase
                  style="margin-left: 2px; margin-right: 13px; margin-top: 3px"
                  :viewbox="'0 0 45 45'"
                  :width="20"
                  ><Statistics
                /></IconBase>
                {{ $t("statistics") }}
              </mega-menu-link>
            </li>

            <li
              v-if="!$store.state.isBoundAdmin"
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{ name: 'Moderation' }"
                icon="i-help-circle"
                old_icon
                exact
              >
                {{ $t("moderation") }}
              </mega-menu-link>
              <router-link
                :to="{ name: 'Support' }"
                class="btn mr-2 p-0 btn-primary btn-xs"
                v-show="$store.state.unreadMessages"
                >{{ $store.state.unreadMessages }}</router-link
              >
            </li>

            <li
              v-if="
                $store.state.isBoundAdmin &&
                  SUPPORT_HAVING_SNS.includes($store.state.selectedSn.id)
              "
              class="d-flex align-items-center"
            >
              <mega-menu-link
                class="w-100"
                :to="{ name: 'Support' }"
                icon="i-help-circle"
                old_icon
                exact
              >
                {{ $t("care_service") }}
              </mega-menu-link>
              <router-link
                :to="{ name: 'Support' }"
                class="btn mr-2 p-0 btn-primary btn-xs"
                v-show="$store.state.unreadMessages"
                >{{ $store.state.unreadMessages }}</router-link
              >
            </li>
          </ul>

          <ul class="menu-nav mt-auto">
            <!--            <li class="nav-item">-->
            <!--              <a-->
            <!--                href="https://itunes.apple.com/ru/app/arround/id1435766899"-->
            <!--                target="_blank"-->
            <!--                class="nav-link"-->
            <!--              >-->
            <!--                <i class="i-apple float-left"></i>-->
            <!--                <span>Apple Store</span>-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-item">-->
            <!--              <a-->
            <!--                href="https://play.google.com/store/apps/details?id=com.arround.io"-->
            <!--                target="_blank"-->
            <!--                class="nav-link"-->
            <!--              >-->
            <!--                <i class="i-android float-left"></i>-->
            <!--                <span>Google Play</span>-->
            <!--              </a>-->
            <!--            </li>-->
            <!--            <li class="nav-item">-->
            <!--              <a-->
            <!--                :href="-->
            <!--                  $store.state.selectedSn-->
            <!--                    ? `https://${$store.state.selectedSn.username}.dgr.am`-->
            <!--                    : 'https://dgr.am'-->
            <!--                "-->
            <!--                target="_blank"-->
            <!--                class="nav-link"-->
            <!--              >-->
            <!--                <i class="i-globe float-left"></i>-->
            <!--                <span>Website</span>-->
            <!--              </a>-->
            <!--            </li>-->

            <li class="menu-divider">
              {{ user.email }}
            </li>

            <li class="nav-item">
              <mega-menu-link
                class="w-100"
                :to="{ name: 'Options' }"
                icon="i-settings"
              >
                {{ $t("options") }}
              </mega-menu-link>
              <span class="nav-link" @click="logOut">
                <i class="i-power float-left"></i>
                <span>{{ $t("logout") }}</span>
              </span>
            </li>
          </ul>
        </perfect-scrollbar>
        <div
          v-show="mouseOver"
          class="menu-collapse"
          @click="menu.minimize_toggle"
        >
          <span
            class="p-1 d-flex align-items-center justify-content-center menu-collapse-btn"
          >
            <i :class="menu.isMini ? 'i-arrow-right' : 'i-arrow-left'"></i>
          </span>
          <div class="menu-collapse-border"></div>
        </div>
      </div>

      <new-model ref="new-model" />
    </div>
  </div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.new-message {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid red;
  background-color: red;
}
.menu-collapse {
  position: absolute;
  height: 100%;
  top: 0;
  right: -5px;
  width: 5px;
  &-enter-active,
  &-leave-active {
    transition: opacity 0.5s;
  }
  &-enter, &-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  &-btn {
    position: absolute;
    background-color: white;
    box-shadow: inset 0px 0px 0px 2px var(--base-color);
    border-radius: 50px;
    width: 30px;
    height: 30px;
    transform: translateY(calc(35px - 50%)) translateX(calc(-50% + 5px / 2));
    cursor: pointer;
  }
  &-border {
    background-color: var(--base-color);
    height: 100%;
    width: 5px;
    cursor: col-resize;
  }
}
.menu-brand {
  &-disabled {
    pointer-events: none;
    cursor: default;
  }
}
</style>

<script>
import NewModel from "./new_model";
import IconBase from "../../components/icons/IconBase.vue";
import Channels from "../../components/icons/set/Channels";
import Side3D from "../../components/icons/set/Side3D.vue";
import SideGif from "../../components/icons/set/SideGif.vue";
import SideVideo from "../../components/icons/set/SideVideo.vue";
import SideImage from "../../components/icons/set/SideImage.vue";
import SideMask from "../../components/icons/set/SideMask.vue";
import SidePortal from "../../components/icons/set/SidePortal.vue";
import Statistics from "../../components/icons/set/Statistics.vue";
import Streams from "../../components/icons/set/Streams.vue";

export default {
  name: "MainSidebar",
  mounted() {
    if (this.$store.state.msgSocket) {
      this.connection = this.$store.state.msgSocket;
    } else {
      this.$store.dispatch("openMsgSocket").then(() => {
        this.connection = this.$store.state.msgSocket;
        this.connection.addEventListener("message", this.newTotal);
      });
    }
    this.getUnreadMesages();
  },
  data() {
    return {
      mouseOver: false,
      connection: "",
      SUPPORT_HAVING_SNS: [9]
    };
  },
  computed: {
    page() {
      return this.$store.state.page;
    },
    user() {
      return this.$store.state.user;
    },
    language() {
      return this.$store.state.lang;
    },
    session() {
      return this.$store.state.token;
    },
    menu() {
      if (process.browser) return this.$mega.menu;

      return {
        open_toggle: () => {}
      };
    },
    selectedSn() {
      return this.$store.state.selectedSn;
    }
  },
  methods: {
    newTotal(response) {
      const action = JSON.parse(response.data);
      const action_name = Object.keys(action)[0];
      if (action_name === "unread_update") {
        this.updateUnread(action);
      }
    },
    getUnreadMesages() {
      this.$api
        .msg("/admin/support/chat/unread-total")
        .then(res =>
          this.$store.commit("setUnreadMessages", res.data.unread_total)
        );
    },
    updateUnread(data) {
      this.$store.commit("setUnreadMessages", data.unread_update.unread_total);
    },
    addImage() {
      this.$refs["new-model"].open({ type: 2 });
    },
    addVideo() {
      this.$refs["new-model"].open({ type: 3 });
    },
    addModel() {
      this.$refs["new-model"].open({ type: 4 });
    },
    addGif() {
      this.$refs["new-model"].open({ type: 5 });
    },
    addMask() {
      this.$refs["new-model"].open({ type: 10 });
    },
    addPortal() {
      this.$refs["new-model"].open({ type: 12 });
    },
    logOut() {
      return this.$store
        .dispatch("logout", { idt_session: this.session })
        .then(() => {
          window.location.reload();
        });
    },
    switchLang() {
      this.$i18n.locale = this.$i18n.locale === "en" ? "ru" : "en";
      this.$store.dispatch("language", this.$i18n.locale);
      this.$router.go(this.$router.currentRoute);
    }
  },
  components: {
    NewModel,
    IconBase,
    Side3D,
    SideGif,
    SideVideo,
    SideImage,
    SideMask,
    SidePortal,
    Statistics,
    Streams,
    Channels
  }
};
</script>
