var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M6.6875 7.5625L12.2875 11.9375L11.5875 8L17.1875 12.8125 M19.1364 1H4.34091C2.49864 1 1 2.49864 1 4.34091V15.3182C1 17.1605 2.49864 18.6591 4.34091 18.6591H6.92773L4.69648 21.2077C4.52466 21.4058 4.54375 21.7089 4.74182 21.8807C4.8325 21.9618 4.94466 22 5.05682 22C5.19045 22 5.3217 21.9451 5.41716 21.8377L8.19727 18.6591H15.28L18.0625 21.8377C18.1556 21.9451 18.2868 22 18.4205 22C18.5326 22 18.6448 21.9618 18.7355 21.8807C18.9335 21.7089 18.9526 21.4058 18.7784 21.2077L16.5495 18.6591H19.1364C20.9786 18.6591 22.4773 17.1605 22.4773 15.3182V4.34091C22.4773 2.49864 20.9786 1 19.1364 1ZM21.5227 15.3182C21.5227 16.6331 20.4513 17.7045 19.1364 17.7045H4.34091C3.02602 17.7045 1.95455 16.6331 1.95455 15.3182V4.34091C1.95455 3.02602 3.02602 1.95455 4.34091 1.95455H19.1364C20.4513 1.95455 21.5227 3.02602 21.5227 4.34091V15.3182Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }