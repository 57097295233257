<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="close()"
  >
    <mega-header
      :title="$t('influencers_promocodes')"
      class="bg-lighter border-bottom row"
      :row="true"
    >
      <router-link
        :to="{
          name: 'User',
          params: { id: userData ? userData.idt_user : '' }
        }"
        class="navbar-label text-dark col-4 mt-auto mb-auto"
      >
        <mega-image
          class="rounded-full navbar-avatar mr-3"
          ratio="1x1"
          :src="userData.avatar"
          style="overflow:hidden;"
        />

        <div class="content" style="width: calc(100% - 78px);">
          <small class="strong text-overflow"
            >{{ userData.name || "- - -" }}
            <IconBase
              v-if="userData.verified"
              viewBox="0 0 312 311"
              :width="15"
              iconColor="#45C1C9"
              ><UserVerified /></IconBase
          ></small>
          <small class="text-muted text-overflow">
            <span
              v-if="device"
              :class="{ 'text-success': device === 'i-android' }"
            >
              <i :class="device"></i> -
            </span>
            {{ userData.username }}
          </small>
        </div>
      </router-link>
      <div class="col-8 row">
        <div class="nav-text text-muted col-4 p-0">
          <i class="i-mail nav-icon"></i>
          <a
            :class="{ 'events-disable text-dark': !userData.email }"
            class="small"
            :href="userData.email && 'mailto:' + userData.email"
            >{{ userData.email || "- - -" }}</a
          >
        </div>

        <div class="nav-text text-muted col-4 p-0">
          <i class="i-eye nav-icon"></i>
          <span class="small">{{ roundviews || "- - -" }}</span>
        </div>

        <div class="nav-text text-muted col-4 p-0">
          <i class="i-phone nav-icon"></i>
          <a
            :class="{ 'events-disable text-dark': !userData.phone }"
            class="small"
            :href="userData.phone && 'tel:' + userData.phone"
            >{{ userData.phone || "- - -" }}</a
          >
        </div>

        <div class="nav-text text-muted col-4 p-0">
          <i class="i-heart nav-icon"></i>
          <span class="small">{{ roundlikes || "- - -" }}</span>
        </div>

        <div class="nav-text text-muted col-4 p-0">
          <i class="i-user-plus nav-icon"></i>
          <span class="small">{{
            $u2d(userData["a_time"], "DD.MM.Y") || "- - -"
          }}</span>
        </div>

        <div class="nav-text text-muted col-4 p-0">
          <i class="i-layers nav-icon"></i>
          <span class="small">{{ userData["round_count"] || "- - -" }}</span>
        </div>
      </div>
      <span class="px-3 pt-3">
        {{ $t("promo_signups") }} - {{ total_signups }}
      </span>
    </mega-header>
    <mega-card :title="$t('promocodes') + ':'">
      <div v-for="(promocode, id) in promocodes" :key="id">
        <mega-dropdown :archived="!!promocode.archived_at">
          <template #title>
            <span class="align-self-center ml-auto mr-auto"
              >{{ promocode.value }} ({{ promocode.invitees_count }})</span
            >
            <div class="ml-auto p-absolute" style="left: 5px; top: -30px">
              <i class="i-calendar small"></i>
              <small>{{
                $u2d(new Date(promocode.created_at), "DD.MM.YYYY")
              }}</small>
              <small v-show="promocode.archived_at"> — </small>
              <small v-if="promocode.archived_at">
                {{ $u2d(new Date(promocode.archived_at), "DD.MM.YYYY") }}</small
              >
              <small v-else>({{ $t("advert_active") }})</small>
            </div>
          </template>
          <div v-for="(element, el_id) in promocode.array" :key="el_id">
            <div class="d-flex py-2 pl-3">
              <router-link
                :to="{
                  name: 'User',
                  params: { id: element ? element.idt_user : '' }
                }"
                class="navbar-label text-dark mt-auto mb-auto w-100"
              >
                <mega-image
                  class="rounded-full navbar-avatar mr-3"
                  ratio="1x1"
                  :src="element.avatar"
                  style="overflow:hidden;"
                />
                <div class="content d-flex flex-column">
                  <small class="strong text-overflow"
                    >{{ element.name || "- - -" }}
                    <IconBase
                      v-if="element.verified"
                      viewBox="0 0 312 311"
                      :width="15"
                      iconColor="#45C1C9"
                      ><UserVerified /></IconBase
                  ></small>
                  <small class="text-muted text-overflow">
                    <span
                      v-if="device"
                      :class="{ 'text-success': device === 'i-android' }"
                    >
                      <i :class="device"></i> -
                    </span>
                    {{ element.username }}
                  </small>
                </div>
                <small class="ml-auto mr-2">
                  {{ $u2d(element.a_time, "DD-MM-YYYY") }}
                </small>
              </router-link>
            </div>
          </div>
          <mega-loading
            :identifier="promocode.idt_invite_code"
            @infinite="fetchUsersList($event, promocode)"
            :forceUseInfiniteWrapper="true"
          ></mega-loading>
        </mega-dropdown>
      </div>
    </mega-card>
  </mega-modal>
</template>

<script>
import IconBase from "../../../../components/icons/IconBase";
import UserVerified from "../../../../components/icons/set/UserVerified";
export default {
  name: "PromoModal",
  components: {
    IconBase,
    UserVerified
  },
  props: {
    userData: Object,
    roundlikes: [Number, String],
    roundviews: [Number, String]
  },
  mounted() {},
  data() {
    return {
      modal: false,
      user: "",
      promocodes: "",
      total_signups: "",
      options: [
        {
          title: "WYLSACOM",
          array: [
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "sixth",
            "seventh",
            "first",
            "second",
            "third",
            "fourth",
            "fifth",
            "sixth",
            "seventh"
          ]
        },
        { title: "WYLSA 2020", array: ["One", "Two", "Three"] }
      ]
    };
  },
  computed: {
    device() {
      let d = undefined;

      if (this.userData["sessions"]) {
        if (!this.userData["sessions"][0]) return d;

        let s = this.userData["sessions"][0]["user_agent"].toLowerCase();

        d = "i-monitor";
        if (s.includes("ios")) d = "i-apple";
        if (s.includes("android")) d = "i-android";
      }

      return d;
    }
  },
  methods: {
    open() {
      this.modal = true;
      if (this.userData.is_influencer && !this.promocodes) {
        this.$api.v2base
          .get("/user/influencers/codes", {
            params: {
              idt_user: this.userData.idt_user
            }
          })
          .then(res => {
            this.promocodes = res.data.reverse();
            this.promocodes.forEach(code => {
              this.$set(code, "array", []);
            });
            this.total_signups = this.promocodes
              .map(item => item.invitees_count)
              .reduce((acc, val) => acc + val);
          });
      }
      document.body.style.overflow = "hidden";
    },
    close() {
      this.modal = false;
      document.body.style.overflow = "auto";
    },
    console(promo) {
      console.log(promo, "PROMO");
    },
    fetchUsersList($event, code) {
      console.log(code);
      this.$api.v2base
        .get("/user/influencers/invitees", {
          params: {
            idt_invite_code: code.idt_invite_code,
            limit: 5,
            offset: code.array.length
          }
        })
        .then(res => {
          if (res.data.invitees.length) {
            let promo = this.promocodes.find(
              promocode => promocode.idt_invite_code == code.idt_invite_code
            );
            $event.loaded();
            code.array.push(...res.data.invitees);
            // promo["offset"] = res.data.offset;
            console.log(promo);
          } else {
            $event.complete();
          }
        });
    }
  }
};
</script>
