<template>
  <mega-modal class="modal modal-sm" :active="modal" @onClose="close">
    <div v-if="modal">
      <div v-if="loading" class="await loading"></div>

      <div
        class=""
        :style="{
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          paddingBottom: ratio ? padding + '%' : ''
        }"
      ></div>
    </div>
  </mega-modal>
</template>

<script>
export default {
  props: {
    ratio: String
  },

  data() {
    return {
      src: "",
      modal: false,
      loading: false
    };
  },

  watch: {
    src: function(newVal) {
      if (!newVal) return;

      let img = new Image();
      img.src = newVal;

      this.loading = true;

      img.onload = () => {
        this.loading = false;
      };
    }
  },

  computed: {
    padding() {
      let r = this.ratio ? this.ratio.toString().split("x") : [4, 3];

      return (r[1] / r[0]) * 100;
    }
  },

  methods: {
    open(url) {
      this.src = url;
      this.modal = true;
    },

    close() {
      document.body.style.overflowY = "auto";
      this.modal = false;

      this.src = "";
    }
  }
};
</script>
