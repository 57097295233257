var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-card",
    [
      _c(
        "div",
        { staticClass: "card-body", staticStyle: { overflow: "hidden" } },
        [
          _c("div", { staticClass: "qr-text pb-2" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("qr_text")) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qr-image-wrapper" }, [
            _c(
              "div",
              {
                staticClass: "qr-image",
                style: { backgroundImage: "url(" + _vm.imageCode + ")" }
              },
              [
                _c("div", { staticClass: "qr-image-overlay" }, [
                  _c(
                    "a",
                    { attrs: { download: "qr.png", href: _vm.imageCode } },
                    [_vm._v(_vm._s(_vm.$t("download")))]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "qr-text pt-2" }, [
            _vm._v(_vm._s(_vm.fullURI))
          ]),
          _vm._v(" "),
          _c("div", { ref: "qrcode" })
        ]
      ),
      _vm._v(" "),
      _c("no-ssr")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }