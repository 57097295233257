var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M0 34V36H33V33L0 34Z M3 0L0.499999 0.72727L0 32C0 32 2.26347 31.2727 3 31.2727V26.3651L8.38278 21.7647L18.7084 27.6471L33.1069 7.29412L35.5 9L34.9426 2L27.5 3L30.3534 5L18.02 23.1176L8.15332 17.5294L3 19.8303L3 0Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }