var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d:
        "M21.1153 19.3156L21.111 19.3333H17.4964L17.3137 17.1474L21.1238 17.1417C23.5288 16.7904 25.3777 14.7253 25.3777 12.2222C25.3777 9.47307 23.149 7.24444 20.3999 7.24444C20.0557 7.24444 19.7193 7.27929 19.3944 7.34613C18.5901 4.81387 16.2221 2.97778 13.4225 2.97778C10.3633 2.97778 7.81961 5.17227 7.27064 8.07147C8.4013 8.29191 9.40397 8.8544 10.1876 9.63449L9.29019 10.5319C8.50655 9.74187 7.62619 9.36924 6.53748 9.36924C4.71135 9.36924 2.6221 10.8889 2.6221 13.2846C2.6221 15.3234 4.18655 16.9788 6.17766 17.1637L13.2745 17.1531L13.6443 8.66667H15.0665L15.7108 23.4969L17.911 22.1778L18.6221 22.8889L14.3554 27.1556L10.0888 22.8889L10.7999 22.1778L13.0001 23.4969L13.1807 19.3333H6.17766C3.03597 19.3333 0.48877 16.7861 0.48877 13.6444C0.48877 10.7317 2.67828 8.33458 5.49997 7.99964C5.83917 3.99253 9.19419 0.844445 13.2888 0.844445C16.6075 0.844445 19.437 2.91449 20.5727 5.83147C20.6345 5.83004 20.6943 5.82222 20.7554 5.82222C24.4859 5.82222 27.511 8.84729 27.511 12.5778C27.511 16.1874 24.6779 19.1271 21.1153 19.3156Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }