var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M9.729 3.086L10.5 0H19.5L20.274 3.097L27.5 4L28.5 8H0.5L1.5 4L9.729 3.086ZM12.5 11V27H10.5L9.5 11H12.5ZM16.5 11H19.5L18.5 27H16.5V11ZM23.717 10H26.34L24.5 33H4.5L2.773 11.424L5.228 10.992L7.698 29.604L21.302 30.603L23.717 10Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }