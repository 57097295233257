import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";

Vue.use(Router);

export function createRouter(store) {
  let router = new Router({
    routes: routes(store),
    mode: "history",
    fallback: false,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) return savedPosition;
      else if (to.hash) return { selector: to.hash };
      else return { x: 0, y: 0 };
    }
  });

  router.beforeEach((to, from, next) => {
    let history = router.app["history"];

    if (history) {
      let index = router.app["history"].indexOf(to.name);

      if (index > -1) history.splice(0, index + 1);
    }

    return next();
  });

  router.afterEach((to, from) => {
    let history = router.app["history"];

    if (history) {
      let index = router.app["history"].indexOf(from.name);

      if (index === -1) router.app["history"].push(from.name);
    }
  });

  return router;
}
