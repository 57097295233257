import Vue from "vue";

let Menu = new Vue({
  data() {
    return {
      isOpen: false,
      isMini: false
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    open_toggle() {
      this.isOpen = !this.isOpen;
    },

    minimize() {
      this.isMini = true;
    },

    maximize() {
      this.isMini = false;
    },

    minimize_toggle() {
      this.isMini = !this.isMini;
    }
  }
});

export default Menu;
