import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    copy: "Copy",
    date: {
      interval: {
        year: "Year",
        quarter: "Quarter",
        month: "Month",
        week: "Week",
        day: "Day",
        hour: "Hour"
      },
      months: {
        short: {
          jan: "Jan",
          feb: "Feb",
          mar: "Mar",
          apr: "Apr",
          may: "May",
          jun: "Jun",
          jul: "Jul",
          aug: "Aug",
          sep: "Sep",
          oct: "Oct",
          nov: "Nov",
          dec: "Dec"
        },
        long: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December"
        }
      }
    },
    view: "view",
    change: "Change",
    no_fav: "No favourite",
    download: "Download",
    unread_chats: "unread chats",
    search: "search",
    chats_per_page: "Chats per page",
    page: "Page",
    load_more: "Load more",
    support: "Support",
    care_service: "Care service",
    you: "You",
    moderation: "Modration",
    select_chat: "Select chat",
    send_message: "Send message...",
    business_public_page: "Business public page",
    // Login screen
    password: "Password",
    forgot_pwd: "Forgot password?",
    login: "Login",
    // Side menu
    dashboard: "Dashboard",
    statistics: "Statistics",
    categories: "Categories",
    streams: "Streams",
    social_networks: "Social networks",
    social_network: "Social network",
    new_sn_name: "New social network name",
    new_sn_name_ru: "New social network name (ru)",
    new_sn_username: "New social network username",
    new_sn_description: "New social network description",
    sn_updated: "Social network updated",
    sn_update_error: "Error updating social network",
    sn_save: "Save",
    sn_create: "Create social network",
    sn_created: "Social network created",
    sn_create_error: "Social network creation error",
    err_file: "Error uploading file",
    users: "Users",
    all_users: "All users",
    business: "Business",
    rounds: "Rounds",
    registrations: "New registrations",
    models: "Models",
    gifs: "GIFs",
    videos: "Videos",
    long_videos: "Long videos",
    images: "Images",
    masks: "Masks",
    portals: "Portals",
    payouts: "Payouts",
    options: "Options",
    logout: "Logout",
    profile: "Profile",
    // Stream screen
    stream_live: "Live",
    no_name: "No name",
    // Category page
    new_category: "Add category",
    edit_category: "Edit Category",
    selected_category: "Selected category",
    active_category: "Active category picture",
    disabled_category: "Disabled category picture",
    category_name: "Category name and localization",
    success: "Success",
    category_del_title: "Delete category",
    category_del_confirm: "Confirm deleting catgory",
    category_fill_text: "Streams from category",
    category_show_icon: "Show category icon on client",
    category_show_text: "Show category text on client",
    // Dashboard page
    new_users: "New users",
    total: "Total: ",
    by_time: "per selected period",
    shares: "Shares",
    ref_detects: "Detects of ref images",
    arr_transitions: "Transitions to business account",
    round_likes: "Round likes",
    video_record_clicks: "Cliks to 'Video record' button",
    photo_make_clicks: "Cliks to 'Photo' button",
    video_record_clicks_install_mode: "Install mode",
    video_record_clicks_around_mode: "Around mode",
    photo_make_clicks_install_mode: "Install mode",
    photo_make_clicks_around_mode: "Around mode",
    arr_visits: "Arr visits",
    round_views: "Round views from feed",
    modif_count: "Modifications count",
    modif_weight: "Modifications size (Mbits)",
    modifications: "Modifications",
    no_data: "Few data",
    install_mode: "'Round Mounting' Mode",
    around_mode: "'Rounds Around' Mode",

    welcome: "Welcome",
    new_round: "New Round",
    new_video: "New Video",
    new_model: "New Model",
    new_group: "New Group",
    view_all: "View All",
    // Users page
    search_placeholder: "Search...",
    vip_status: "VIP status",
    vip_status_holder: "Set VIP status",
    no_vip: "None",
    vip: "Vip",
    vip_pro: "S-Vip",
    // Businesses page
    business_owner: "Page owner",
    business_admins: "Administrations",
    qr_code: "QR Code",
    new_business: "New Business",
    businesses: "Businesses",
    interesting_businesses: "Favourites",
    //Businesses_fav page
    back: "Back",
    // Rounds page
    open_map: "Open Map",
    open_list: "Open List",
    round_del_title: "Delete round(s)",
    round_del_confirm: "Confirm that you are sure you want to delete round(s)",
    round_download_title: "Download round(s)",
    round_download_confirm: "Confirm round(s) download",
    round_likes_input: "likes",
    round_views_input: "views",
    round_views_success: "Successfully changed",
    round_views_greater: "Views number should be greater than zero",
    round_likes_greater: "Likes number should be greater than zero",
    round_likes_success: "Successfully changed",
    rounds_promoted: "Recommended rounds",
    round_likes_label: "Likes",
    round_del_from_top: "Deleted from recommended",
    round_del_top_title: "Delete from recommended?",
    round_del_top_confirm: "Delete this round from recommended?",
    advertising_company: "Settings of advertising company",
    // Models page
    all_models: "Popular models",
    wo_group: "Without group",
    groups: "Groups",
    all: "All",
    active: "Active",
    disabled: "Disabled",
    edit_group: "Edit Group",
    // Model card
    card_refs_status: "No references",
    // User page
    sex: "Sex",
    user: "User",
    sex_type_1: "Not set",
    sex_type_2: "Female",
    sex_type_3: "Male",
    activity: "Activity",
    sessions: "Sessions",
    user_sessions: "User Sessions",
    last_rounds: "Last Rounds",
    popular_rounds: "Popular Rounds",
    popular_users: "Popular Users",
    popular_models: "Popular Models",
    popular_businesses: "Popular Businesses",
    popular_images: "Popular Images",
    last_models: "Last Models",
    user_verified: "Verified",
    delete_request: "Delete request",
    // Business page
    set_owner: "Set Owner",
    business_owner_desc:
      "Enter email to set ARR Owner. New Owner will receive email with instructions",
    business_owner_new: "Set new Owner",
    business_block_deactivate: "Deactivate",
    business_block_activate: "Activate",
    business_decomposition_interval: "X-axis partitioning",
    graph_date: "Start/end date",
    graph_start_date: "Start date",
    graph_end_date: "End date",
    // Round page
    round: "Round",
    video: "Video",
    comments: "Comments",
    reposts: "Reposts",
    views: "Views",
    views_from_user_and_business: "Business and Users rounds views",
    likes: "Likes",
    likes_now: "Likes now",
    dislikes: "Dislikes",
    round_msg: "Round Message",
    round_pos: "Round Position",
    round_model: "Round Model",
    round_to_top: "Send to Recommended",
    round_to_top_history: "Send to Recommended History",
    round_to_top_date: "Date",
    round_to_top_day_1: "days",
    round_to_top_day_2: "days",
    round_to_top_day_3: "days",
    round_favs: "Favourites",
    round_top_forall: "For all",
    round_top_not_selected: "Not selected",
    round_top_sex: "Sex",
    round_top_sex_f: "Female",
    round_top_sex_m: "Male",
    round_top_age: "Age",
    round_top_age_exact: "Exact",
    round_top_age_range: "Range",
    round_top_loc: "Location",
    round_top_loc_city: "City",
    round_top_loc_country: "Country",
    open_web: "Open in Web",
    deletion: "Deletion",
    del: "DELETE",
    del_confirm: "Confirm that you are sure you want to delete the round.",
    del_adcomp: "Delete advertisement company",
    ads_round: "Advertisement round",
    del_round: "Delete round",
    block: "Block",
    del_from_video_feed: "Delete from video feed",
    del_from_recommended_feed: "Delete from recommended feed",
    close: "Close",
    // Round Post
    advert_round: "Advertising round",
    advert_duration: "Advertising company duration",
    advert_place: "Where to show",
    advert_on_app_enter: "On app enter",
    advert_on_feed: "Feed",
    advert_active: "Active",
    advert_frequency: "Total shows number",
    advert_number: "Ad frequency",
    advert_result_link: "Ad result link",
    advert_success: "Ad campain created",
    round_plane_height: "Scene height",
    round_create_title: "Select round type",
    // Round Post page
    round_photo: "Photo Round",
    round_3d: "3D Round",
    round_yt: "Youtube Round",
    round_gif: "Gif Round",
    round_video: "Video Round",
    //Round Post Video page
    make_round_video: "Make Video Round",
    make_video: "Make Video",
    video_opt: "Video options",
    title: "Title",
    round_too_long: "Video too long to be a round",
    please_reupload:
      "Please, reupload it as a long video or choose a video shorter than 60 seconds",
    redirect: "Redirect me",
    // Round Post Photo page
    make_round_photo: "Make Photo Round",
    crop_photo: "Crop photo",
    confirm: "Confirm",
    cancel: "Cancel",
    visible: "Visible in Recomendation feed",
    round_options: "Round Options",
    sel_user: "Select User",
    scale: "Scale",
    height: "Height (cm)",
    desc: "Description",
    lat: "Lat",
    lng: "Lng",
    place_name: "Place Name",
    place_address: "Place Address",
    no_address: "No address",
    location_hidden: "Location hidden",
    hide_loc: "Hide Location",
    hide_loc_desc:
      "You can hide location from Public, but is still be in Round details",
    round_post: "Post Round",
    // Round Post 3D page
    make_round_3d: "Make 3D Round",
    // Round Post Youtube
    make_round_yt: "Create Youtube Round",
    round_yt_title: "Youtube Video",
    round_yt_url: "Video URL or ID",
    // New model popup
    new_model_desc: "Enter model details",
    new_model_name: "Name",
    round_type: "Round Type",
    type_holder: "Type is not selected",
    group: "Group",
    group_holder: "Group is not selected",
    save: "Save",
    // New group popup
    new_group_desc: "Group is menu item in application for organizing models",
    new_group_name: "Group Name",
    group_type: "Group Type",
    group_type_holder: "Type is not selected",
    // New business popup
    new_business_desc:
      "Business are where customers can load models and post rounds from their admin panel. They’re best when you need to add new ARR in around world",
    new_business_name: "Business Name",
    new_business_add: "Add Business",
    // Model page
    model_modification: "Modification",
    model_warn: "Warning",
    model_title: "Model",
    model_name: "Name",
    model_group: "Group",
    model_group_holder: "Select group",
    model_show: "Show Model",
    model_make_exclusive: "Make exclusive",
    model_exclusive: "Exclusive",
    exclusive_label: "Exclusive label",
    exclusive_label_ru: "Ru recognize label",
    exclusive_label_en: "En recognize label",
    model_modifications: "Modifications",
    model_mod_show: "Make active",
    model_mod_name: "Modification Name",
    model_vis: "Visibility",
    model_vis_ios: "Visible on iOS",
    model_vis_android: "Visible on Android",
    model_vis_web: "Visible on Web",
    model_ios_warn: "The model has no iOS Archive",
    model_android_warn: "The model has no Android Archive",
    model_ios_mask_warn: "The mask has no iOS Archive",
    model_ios_tex_warn: "The mask has no iOS Texture",
    model_android_sfb_mask_warn: "The mask has no Android SFB",
    model_android_texture_mask_warn: "The mask has no Android Texture",
    model_web_mask_warn: "The mask has no Web Archive",
    model_archives: "Archives",
    model_arch_prev: "Preview",
    model_arch_dl: "Download",
    model_arch_upl: "Upload",
    model_audio: "Audio",
    model_audio_file: "Audio File",
    audio_play: "Play",
    audio_mode: "Audio Mode",
    audio_mode_once: "Once",
    audio_mode_loop: "Loop",
    audio_mode_tap: "On tap",
    audio_mode_dis: "Disabled",
    model_rec_imgs: "Recognize Images",
    model_rec_old: "Old Recognize",
    model_rec_server: "Recognize Images On Server",
    model_save: "Save",
    model_updated: "Updated",
    // Gif page
    gif_title: "Gif",
    gif_name: "Gif Name",
    gif_group: "Gif Group",
    gif_show: "Show Gif",
    gif_vis: "Gif Visibility",
    gif_src: "Gif Source",
    gif_imgs: "Gif Images",
    gif_warn: "The Gif has no uploaded source",
    // Video page
    video_title: "Video",
    video_name: "Video Name",
    video_group: "Video Group",
    video_show: "Show Video",
    video_vis: "Video Visibility",
    video_src: "Video Source",
    video_new: "Replace",
    video_imgs: "Video Images",
    video_warn: "The Video has no uploaded source",
    // Image page
    img_title: "Image",
    img_name: "Image Name",
    img_group: "Image Group",
    img_show: "Show Image",
    img_vis: "Image Visibility",
    img_imgs: "Image References",
    // Mask page
    mask_title: "Mask",
    // Ref image create
    ref_title: "New Ref Image",
    ref_sub_title: "Enter image details",
    ref_pic_width: "Width of picture in real world (cm)",
    ref_global_loc: "Global Location",
    ref_rec_server: "Server Recognize",
    ref_rec_percent: "Recognize accuracy (%)",
    ref_sticky: "Sticky",
    ref_new_msg: "New ref image was added",
    ref_edit_msg: "Ref image was modified",
    // Ref image edit
    ref_edit_btn: "Edit",
    ref_edit_title: "Edit Ref Image",
    ref_del_confirm: "Confirm that you are sure you want to delete the image",
    ref_del: "Delete image",
    ref_exist_btn: "Exist",
    ref_ocr: "Words from image",
    ref_key_words: "Key words (separated by comma)",
    ref_info_name: "Name",
    ref_info_date: "Creation Date",
    ref_info_on: "On",
    ref_info_off: "Off",
    ref_info_key_words: "Key words",
    // Options page
    //opt_lifetime_days: 'day(s)',
    opt_lifetime_title: "Round limit on map (in days)",
    // ARR Block
    arr_manager: "ARR Manager",
    arr_mine: "My ARR's",
    arr_invite_adm: "Invite admin",
    arr_invite_desc:
      "Enter email to invite him in ARR. New Admin will receive email with instructions",
    arr_invite_btn: "Invite",
    arr_name: "Name",
    arr_site: "Site",
    arr_phone: "Phone",
    arr_about: "About",
    arr_links: "Links",
    arr_admins: "Admins",
    arr_profile_fname: "First Name",
    arr_profile_lname: "Last Name",
    arr_profile_uname: "Username",
    arr_admin_del_title: "Delete admin",
    arr_admin_del_desc: "Are you sure you want to delete",
    // Errors
    err_ref_file: "File format not supported",
    err_ref_small: "Ref image is too small",
    err_ref_quality: "Ref image quality is too low",
    err_ref_match: "Ref image matches other image",
    err_server: "Server error",
    err_ref_size: "Image size less than recommended",
    err_ref_size_more: "Image size more than recommended",
    err_ref_chars: "Wrong chars in key words",
    // Success
    qr_text: "You can scan this QR Code in the AROUND app",
    succ_andr_arch_upd: "Android archive updated",
    succ_ios_arch_upd: "iOS archive updated",
    succ_web_arch_upd: "WEB archive updated",
    succ_mask_arch_upd: "Mask updated",
    succ_portal_arch_upd: "Portal updated",
    succ_audio_upd: "Audio updated",
    //Influencers
    influencers: "Influencers",
    influencers_promocodes: "Influencer's promo codes",
    promocode: "Promo code",
    promocodes: "Promo codes",
    influencer: "Influencer",
    promo_signups: "Signups by influencer's promocode",
    // Channels
    channels: "Channels",
    filters: "Filters",
    no_channels: "No channels",
    no_posts: "No posts",
    invite_link: "Invite link",
    copied: "Link copied to clipboard",
    // Payouts
    create_reports: "Create reports",
    create_reports_desc: "Use to generate reports for a selected month",
    income: "Income",
    user_type: "User type",
    income_perc: "Income",
    evangelist: "Evangelist",
    producer: "Producer",
    content_ad_sum: "Content ads",
    public_ad_sum: "Public ads",
    sum: "Sum",
    request: "Request",
    profit_perc: "Profit percent",
    set_profit_perc: "Set profit percent",
    round_mass_recommend: "Recomend a number of rounds",
    round_recommend_number: "How many rounds to recommend"
  },
  ru: {
    copy: "Скопировать",
    date: {
      interval: {
        year: "Год",
        quarter: "Квартал",
        month: "Месяц",
        week: "Неделя",
        day: "День",
        hour: "Час"
      },
      months: {
        short: {
          jan: "янв.",
          feb: "февр.",
          mar: "март",
          apr: "апр.",
          may: "май",
          jun: "июнь",
          jul: "июль",
          aug: "авг.",
          sep: "сент.",
          oct: "окт.",
          nov: "нояб.",
          dec: "дек."
        },
        long: {
          january: "Январь",
          february: "Февраль",
          march: "Март",
          april: "Апрель",
          may: "Май",
          june: "Июнь",
          july: "Июль",
          august: "Август",
          september: "Сентябрь",
          october: "Октябрь",
          november: "Ноябрь",
          december: "Декабрь"
        }
      }
    },
    view: "смотреть",
    change: "Изменить",
    no_fav: "Интересные",
    download: "Скачать",
    unread_chats: "непрочитаные чаты",
    search: "поиск",
    chats_per_page: "Чатов на странице",
    page: "Страница",
    load_more: "Загрузить еще",
    support: "Поддержка",
    care_service: "Служба заботы",
    you: "Вы",
    moderation: "Модерация",
    select_chat: "Выберите чат",
    send_message: "Отправить сообщение...",
    business_public_page: "Публичная страница бизнеса",
    // Login screen
    password: "Пароль",
    forgot_pwd: "Забыли пароль?",
    login: "Войти",
    // Side menu
    dashboard: "Дашборд",
    statistics: "Статистика",
    categories: "Категории",
    social_networks: "Социальные сети",
    social_network: "Социальная сеть",
    new_sn_name: "Название социальной сети",
    new_sn_name_ru: "Название социальной сети на русском",
    new_sn_username: "Username социальной сети",
    new_sn_description: "Описание социальной сети",
    sn_updated: "Социальная сеть обновлена",
    sn_update_error: "Ошибка обновления социальной сети",
    sn_save: "Сохранить",
    sn_create: "Создать соц. сеть",
    sn_created: "Соц. сеть создана",
    sn_create_error: "Ошибка создания соц. сети",
    err_file: "Ошибка загрузки файла",
    users: "Пользователи",
    all_users: "Все пользователи",
    business: "Бизнес",
    streams: "Стримы",
    rounds: "Раунды",
    registrations: "Новые регистрации",
    models: "Модели",
    gifs: "GIFки",
    videos: "Видео",
    long_videos: "Длинные видео",
    images: "Изображения",
    masks: "Маски",
    portals: "Порталы",
    payouts: "Выплаты",
    options: "Настройки",
    logout: "Выйти",
    profile: "Профиль",
    // Stream screen
    stream_live: "В ЭФИРЕ",
    no_name: "Безымянный",
    // Category page
    new_category: "Добавить категорию",
    edit_category: "Редактировать",
    selected_category: "Выбрана категория",
    active_category: "Иконка категории",
    disabled_category: "Иконка отключенной категории",
    category_name_loc: "Название и локализация категорий",
    category_name: "Название категории",
    success: "Успешно",
    category_del_title: "Удаление категории",
    category_del_confirm: "Подтвердите удаление категории",
    category_fill_text: "Стримы из категории",
    category_show_text: "Показывать текст в приложении",
    category_show_icon: "Показывать иконку категории в приложении",
    // Dashboard page
    new_users: "Новые пользователи",
    total: "Всего: ",
    by_time: "за выбранный период",
    shares: "Поделились",
    ref_detects: "Распознаваний ref изображений",
    arr_transitions: "Переходов на бизнес-аккаунт",
    round_likes: "Кол-во лайков на раундах",
    video_record_clicks: "Количество нажатий 'Сделать видео' в разных режимах:",
    photo_make_clicks: "Количество нажатий 'Сделать фото' в разных режимах:",
    video_record_clicks_install_mode: "В режиме установки",
    video_record_clicks_around_mode: "В режиме вокруг",
    photo_make_clicks_install_mode: "В режиме установки",
    photo_make_clicks_around_mode: "В режиме вокруг",
    arr_visits: "Визитов на бизнес-страницы",
    round_views: "Просмотров раундов из ленты",
    modif_count: "Количество модификаций",
    modif_weight: "Размер модификаций (Мбайт)",
    modifications: "Модификации",
    no_data: "Мало данных",
    install_mode: "Режим 'Установка Раунда'",
    around_mode: "Режим 'Раунды вокруг'",

    welcome: "Привет",
    new_round: "Новый раунд",
    new_video: "Новое видео",
    new_model: "Новая модель",
    new_group: "Новая группа",
    view_all: "Посмотреть все",
    // Users page
    search_placeholder: "Найти...",
    // Businesses page
    business_owner: "Владелец страницы",
    business_admins: "Администраторы",
    qr_code: "QR Код",
    new_business: "Новый бизнес",
    businesses: "Бизнесы",
    interesting_businesses: "Интересное",
    //Businesses_fav page
    back: "Назад",
    // Rounds page
    open_map: "Открыть карту",
    open_list: "Открыть список",
    round_del_title: "Удалить раунд(ы)",
    round_del_confirm: "Подтвердите удаление раунда(ов)",
    round_download_title: "Скачать раунд(ы)",
    round_download_confirm: "Подтвердите скачавание раунда(ов)",
    round_likes_input: "Лайки",
    round_views_input: "Просмотры",
    round_views_success: "Успешно изменено",
    round_views_greater: "Число просмотров должно быть больше нуля",
    round_likes_greater: "Число лайков должно быть больше нуля",
    round_likes_success: "Успешно изменено",
    round_likes_label: "Лайки",
    rounds_promoted: "Поднятые в Рекомендуемые",
    round_del_from_top: "Удалено из Рекомендованных",
    round_del_top_title: "Удалить из Рекомендованных?",
    round_del_top_confirm: "Удалить следующий раунд из Рекомендованных?",
    advertising_company: "Настройка рекламной кампании",
    // Models page
    all_models: "Популярные модели",
    wo_group: "Без группы",
    groups: "Группы",
    all: "Все",
    active: "Активные",
    disabled: "Неактивные",
    edit_group: "Изменить группу",
    // Model card
    card_refs_status: "Без референсов",
    // User page
    sex: "Пол",
    user: "Пользователь",
    sex_type_1: "Не задан",
    sex_type_2: "Женский",
    sex_type_3: "Мужской",
    activity: "Активность",
    sessions: "Сессии",
    user_sessions: "Пользовательские сессии",
    last_rounds: "Последние раунды",
    popular_rounds: "Популярные раунды",
    popular_users: "Популярные пользователи",
    popular_models: "Популярные модели",
    popular_businesses: "Популярные бизнесы",
    popular_images: "Популярные Ref-изображения",
    last_models: "Последние модели",
    user_verified: "Верифицирован",
    delete_request: "Запрос на удаление",
    vip_status: "Статус VIP",
    vip_status_holder: "Задать статус VIP",
    no_vip: "Нет",
    vip: "Vip",
    vip_pro: "S-Vip",
    // Business page
    set_owner: "Назначить владельца",
    business_owner_desc:
      "Введите e-mail, чтобы задать владельца ARR. Новый владелец получит интсрукции на email",
    business_owner_new: "Задать владельца",
    business_block_deactivate: "Деактивировать",
    business_block_activate: "Активировать",
    business_decomposition_interval: "Интервал разбиения",
    graph_date: "Период расчета статистики",
    graph_start_date: "Дата начала",
    graph_end_date: "Дата конца",
    // Round page
    round: "Раунд",
    video: "Видео",
    comments: "Комментарии",
    reposts: "Репосты",
    views: "Просмотры",
    views_from_user_and_business: "Просмотры раундов пользователей и бизнесов",
    likes: "Нравится",
    likes_now: "Лайков сейчас",
    dislikes: "Не нравится",
    round_msg: "Сообщение",
    round_pos: "Местоположение",
    round_model: "Модель раунда",
    round_to_top: "Поднять в Рекомендуемые",
    round_to_top_history: "История поднятий в Рекомендуемые",
    round_to_top_date: "Дата",
    round_to_top_day_1: "дней",
    round_to_top_day_2: "дня",
    round_to_top_day_3: "день",
    round_favs: "Избранные",
    round_top_forall: "Для всех",
    round_top_not_selected: "Не выбрано",
    round_top_sex: "Пол",
    round_top_sex_f: "Женский",
    round_top_sex_m: "Мужской",
    round_top_age: "Возраст",
    round_top_age_exact: "Точный",
    round_top_age_range: "Диапазон",
    round_top_loc: "Локация",
    round_top_loc_city: "Город",
    round_top_loc_country: "Страна",
    open_web: "Открыть",
    deletion: "Удаление",
    del: "Удалить",
    del_adcomp: "Удалить рекламную кампанию",
    ads_round: "Раунд рекламный",
    del_confirm: "Вы действительно хотите удалить этот раунд?",
    del_round: "Удалить раунд",
    block: "Заблокировать",
    del_from_video_feed: "Удалить из ленты видео",
    del_from_recommended_feed: "Удалить из ленты рекомендуем",
    close: "Отмена",
    // Round Post
    advert_round: "Сделать рекламным",
    advert_duration: "Продолжительность рекламной компании",
    advert_place: "Место показа",
    advert_on_app_enter: "При входе в приложение",
    advert_on_feed: "В ленте",
    advert_active: "Активен",
    advert_frequency: "Общее кол-во показов",
    advert_number: "Частота показа рекламных раундов",
    advert_result_link: "Ссылка для перехода",
    advert_success: "Рекламная кампания успешно создана",
    round_plane_height: "Высота сцены",
    round_create_title: "Выберите тип раунда",
    // Round Post page
    round_photo: "Фото раунд",
    round_3d: "3D раунд",
    round_yt: "Youtube раунд",
    round_gif: "Gif раунд",
    round_video: "Видео раунд",
    //Round Post Video page
    make_round_video: "Создать видео раунд",
    make_video: "Создать видео",
    video_opt: "Параметры видео",
    title: "Название",
    round_too_long: "Загружаемое видео слишком длинное для раунда",
    please_reupload:
      "Пожалуйста, загрузите его как длинное видео или выберите видео короче 60 секунд.",
    redirect: "Перейти",
    // Round Post Photo page
    make_round_photo: "Создать фото раунд",
    confirm: "Подтвердить",
    cancel: "Отменить",
    crop_photo: "Обрежьте фото под подходящий размер",
    visible_exp: "Виден в ленте 'Рекомендуем'",
    round_opt: "Параметры раунда",
    sel_user: "Выбрать пользователя",
    round_options: "Настройки раунда",
    scale: "Масштаб",
    height: "Высота (см)",
    desc: "Описание",
    lat: "Широта",
    lng: "Долгота",
    place_name: "Название места",
    place_address: "Адрес места",
    no_address: "Без адреса",
    location_hidden: "Адрес скрыт",
    hide_loc: "Скрыть местоположение",
    hide_loc_desc:
      "Местоположение можно скрыть, но оно все ещё будет доступно в описании раунда",
    round_post: "Создать раунд",
    // Round Post 3D page
    make_round_3d: "Создать 3D раунд",
    // Round Post Youtube
    make_round_yt: "Создать Youtube раунд",
    round_yt_title: "Youtube видео",
    round_yt_url: "Видео URL или ID",
    // New model popup
    new_model_desc: "Укажите параметры модели",
    new_model_name: "Название",
    round_type: "Тип раунда",
    type_holder: "Тип не выбран",
    group: "Группа",
    group_holder: "Группа не выбрана",
    save: "Создать",
    // New group popup
    new_group_desc: "Группы служат для организации моделей",
    new_group_name: "Название группы",
    group_type: "Тип группы",
    // New business popup
    new_business_desc:
      "Бизнес позволяет покупателям загружать модели или создавать раунды из их админ панели. Создайте бизнес, если хотите добавить новый ARR в окружающий мир",
    new_business_name: "Название бизнеса",
    new_business_add: "Создать бизнес",
    // Model page
    model_modification: "Модификация",
    model_warn: "Внимание",
    model_title: "Модель",
    model_name: "Название",
    model_group: "Группа",
    model_group_holder: "Выбрать группу",
    model_show: "Активировать",
    model_make_exclusive: "Сделать эксклюзивной",
    model_exclusive: "Эксклюзивные",
    exclusive_label: "Эксклюзивный лейбл",
    exclusive_label_ru: "Лейбл при распознавании на русском",
    exclusive_label_en: "Лейбл при распознавании на английском",
    model_modifications: "Модификации",
    model_mod_show: "Активировать",
    model_mod_name: "Название модификации",
    model_vis: "Видимость",
    model_vis_ios: "Видно на iOS",
    model_vis_android: "Видно на Android",
    model_vis_web: "Видно в Web",
    model_ios_warn: "Нет связанного iOS архива",
    model_android_warn: "Нет связанного Android архива",
    model_ios_mask_warn: "Нет iOS Zip файла",
    model_ios_tex_warn: "Нет iOS Texture файла",
    model_android_sfb_mask_warn: "Нет Android SFB файла",
    model_android_texture_mask_warn: "Нет Android Texture файла",
    model_web_mask_warn: "Нет маски для Web",
    model_archives: "Архивы",
    model_arch_prev: "Предпросмотр",
    model_arch_dl: "Скачать",
    model_arch_upl: "Загрузить",
    model_audio: "Аудио",
    model_audio_file: "Аудио файл",
    audio_play: "Играть",
    audio_mode: "Режим",
    audio_mode_once: "Один раз",
    audio_mode_loop: "Цикл",
    audio_mode_tap: "По тапу",
    audio_mode_dis: "Отключён",
    model_rec_imgs: "Изображения для распознавания",
    model_rec_old: "Старое распознавание",
    model_rec_server: "Распознавание на сервере",
    model_save: "Сохранить",
    model_updated: "Обновлено",
    // Gif page
    gif_title: "Gif",
    gif_name: "Название Gif",
    gif_group: "Группа Gif",
    gif_show: "Сделать активным",
    gif_vis: "Видимость Gif",
    gif_src: "Оригинал Gif",
    gif_imgs: "Изображения для распознавания",
    gif_warn: "Оригинал Gif не загружен",
    // Video page
    video_title: "Видео",
    video_name: "Название видео",
    video_group: "Группа видео",
    video_show: "Сделать активным",
    video_vis: "Видимость видео",
    video_src: "Оригинал видео",
    video_new: "Заменить",
    video_imgs: "Изображения для распознавания",
    video_warn: "Оригинал видео не загружен",
    // Image page
    img_title: "Изображение",
    img_name: "Название изображения",
    img_group: "Группа изображения",
    img_show: "Сделать активным",
    img_vis: "Видимость изображения",
    img_imgs: "Изображения для распознавания",
    // Mask page
    mask_title: "Маска",
    // Ref image create
    ref_title: "Новое изображение",
    ref_sub_title: "Укажите параметры изображения",
    ref_pic_width: "Ширина картинки в реальном мире (см)",
    ref_global_loc: "Глобальное отображение",
    ref_rec_server: "Распознавание сервером",
    ref_rec_percent: "Точность распознавания (%)",
    ref_sticky: "Прилипнуть к метке",
    ref_new_msg: "Новое изображение добавлено",
    ref_edit_msg: "Изображение изменено",
    // Ref image edit
    ref_edit_btn: "Редактировать",
    ref_edit_title: "Редактировать изображение",
    ref_del_confirm: "Подтвердите удаление",
    ref_del: "Удалить изображение",
    ref_exist_btn: "Существует",
    ref_ocr: "Слова с изображения",
    ref_key_words: "Ключевые слова (через запятую)",
    ref_info_name: "Название",
    ref_info_date: "Дата добавления",
    ref_info_on: "Да",
    ref_info_off: "Нет",
    ref_info_key_words: "Ключевые слова",
    // Options page
    //opt_lifetime_days: 'день(s)',
    opt_lifetime_title: "Лимит раундов на карте (в днях)",
    // ARR Block
    arr_manager: "Менеджер ARR",
    arr_mine: "Мои ARR'ы",
    arr_invite_adm: "Пригласить админа",
    arr_invite_desc: "Введите email администратора, чтобы его пригласить",
    arr_invite_btn: "Пригласить",
    arr_name: "Имя",
    arr_site: "Сайт",
    arr_phone: "Телефон",
    arr_about: "О себе",
    arr_links: "Ссылки",
    arr_admins: "Админы",
    arr_profile_fname: "Имя",
    arr_profile_lname: "Фамилия",
    arr_profile_uname: "Никнейм",
    arr_admin_del_title: "Удалить админа",
    arr_admin_del_desc: "Вы уверены, что хотите удалить",
    // Errors
    err_ref_file: "Формат файла не поддерживается",
    err_ref_small: "Изображение слишком маленькое",
    err_ref_quality: "Низкое качество изображения",
    err_ref_match: "Совпадает с другим изображением",
    err_server: "Ошибка сервера",
    err_ref_size: "Размер изображения меньше рекомендуемого",
    err_ref_size_more: "Размер изображения больше рекомендуемого",
    err_ref_chars: "Некорректные символы в ключевых словах",
    // Success
    qr_text: "Вы можете отсканировать это изображение в приложении ARROUND",
    succ_andr_arch_upd: "Обновлён Android архив",
    succ_ios_arch_upd: "Обновлён iOS архив",
    succ_web_arch_upd: "Обновлён Web архив",
    succ_mask_arch_upd: "Маска обновлена",
    succ_portal_arch_upd: "Портал обновлен",
    succ_audio_upd: "Аудио обновлено",
    //Influencers
    influencers: "Инфлюенсеры",
    influencers_promocodes: "Промокоды инфлюенсера",
    promocode: "Промокод",
    promocodes: "Промокоды",
    influencer: "Инфлюенсер",
    promo_signups: "Регистраций по промокоду инфлюенсера",
    // Channels
    channels: "Каналы",
    filters: "Фильтры",
    no_channels: "Каналов нет",
    no_posts: "Постов нет",
    invite_link: "Пригласительная ссылка",
    copied: "Ссылка скопирована в буфер обмена",
    // Payouts
    create_reports: "Создать отчеты",
    create_reports_desc:
      "Используйте, чтобы сгенерировать отчеты за указанный месяц",
    income: "Прибыль",
    user_type: "Тип пользователя",
    income_perc: "Начисл.",
    evangelist: "Евангелист",
    producer: "Продюссер",
    content_ad_sum: "Контент рекл.",
    public_ad_sum: "МОП рекл.",
    sum: "Итого",
    request: "Запрос",
    profit_perc: "Процент начислений",
    set_profit_perc: "Установить процент начислений",
    round_mass_recommend: "Поднять несколько в рекомендуемое",
    round_recommend_number: "Сколько раундов поднять в рекомендуемое"
  }
};

export function createLocalization() {
  return new VueI18n({
    locale: "ru", // set locale
    fallbackLocale: "en", // if localization message doesn't exist
    messages // set locale messages
  });
}
