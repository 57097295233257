var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M349.446,234.369h-68.999c-9.251,0-16.75,7.5-16.75,16.75s7.5,16.75,16.75,16.75h68.999c9.251,0,16.75-7.5,16.75-16.75\n\t\t\tS358.697,234.369,349.446,234.369z M349.446,174.366h-68.999c-9.251,0-16.75,7.5-16.75,16.75s7.5,16.75,16.75,16.75h68.999c9.251,0,16.75-7.5,16.75-16.75\n\t\t\tS358.697,174.366,349.446,174.366z M489.693,32.51l-280.307,98.585L91.35,128.168c-10.899-0.277-21.309,3.818-29.109,11.428\n\t\t\tc-13.716,13.379-12.146,28.55-12.146,38.976C19.855,178.573,0,201.743,0,227.116c0,25.219,19.677,48.544,50.095,48.544v11.135\n\t\t\tc0,21.834,17.761,39.89,39.591,40.251l15.377,0.255l-9.896,98.45c-2.935,29.198,20.018,54.701,49.478,54.701h0.108\n\t\t\tc25.643,0,46.913-19.24,49.478-44.754l1.989-19.783c42.724,9.015,83.483-19.386,91.15-61.06l202.623,66.97\n\t\t\tc10.781,3.563,22.008-4.444,22.008-15.904V48.312C512,36.768,500.575,28.683,489.693,32.51z M50.095,245.172\n\t\t\tc-13.205,0-19.607-9.309-19.607-18.056c0-8.787,6.427-18.056,19.607-18.056V245.172z M163.897,432.648\n\t\t\tc-0.993,9.872-9.223,17.315-19.143,17.315h-0.108c-11.355,0-20.284-9.822-19.143-21.164l10.152-100.994l37.206,0.615\n\t\t\tL163.897,432.648z M195.294,295.286L90.24,293.549c-3.664-0.06-6.644-3.091-6.644-6.755V168.413c0-3.657,2.956-6.756,6.756-6.756\n\t\t\tl104.942,2.6V295.286z M201.077,382.659l5.308-54.448c0,0,26.793,8.731,48.651,15.955\n\t\t\tC253.067,370.863,227.235,389.524,201.077,382.659z M478.499,382.743c-9.309-3.076-240.663-79.543-249.705-82.532v-140.43\n\t\t\tl249.705-87.822V382.743z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }