<template>
  <div class="container">
    <mega-card class="rounded h-100 mb-0">
      <div
        @click="
          $route.name === 'Dashboard'
            ? $emit('open-qr', arr['idt_arp'])
            : (modal = !modal)
        "
      >
        <IconBase
          viewBox="0 0 24 24"
          :width="24"
          :height="24"
          class="qr-scan cursor-pointer text-muted"
          style="z-index: 10"
          ><ScanQR
        /></IconBase>
      </div>
      <div class="navbar bg-white border-bottom">
        <router-link
          :to="{ name: 'Business', params: { id: arr.idt_arp } }"
          class="container-fluid"
        >
          <div class="w-100 navbar-label mr-auto text-dark py-3">
            <mega-image
              style="overflow:hidden"
              class="rounded-full navbar-avatar mr-3"
              ratio="1x1"
              small
              :src="arr.logo"
            />

            <div class="content" style="width: calc(100% - 78px);">
              <small class="strong text-overflow">{{ arr.name }} </small>
              <small class="text-muted text-overflow">{{
                "@arr_" + arr.idt_arp
              }}</small>
            </div>
          </div>
        </router-link>
      </div>

      <nav class="nav row font-small w-100 pl-3">
        <div class="nav-text text-muted col-8 p-0 pl-2">
          <i class="i-globe nav-icon"></i>
          <a
            :class="{ 'events-disable text-dark': !arr.site }"
            :href="arr.site && arr.site"
            target="_blank"
            >{{ arr.site || "- - -" }}</a
          >
        </div>

        <div class="nav-text text-muted p-0 col-4">
          <i class="i-heart nav-icon"></i>
          <span>{{ arrLikes || "- - -" }}</span>
        </div>

        <div class="nav-text text-muted col-8 p-0 pl-2">
          <i class="i-phone nav-icon"></i>
          <a
            :class="{ 'events-disable text-dark': !arr.phone }"
            :href="arr.phone && 'tel:' + arr.phone"
            >{{ arr.phone || "- - -" }}</a
          >
        </div>

        <div class="nav-text text-muted p-0 col-4">
          <i class="i-eye nav-icon"></i>
          <span>{{ arrRoundViews || "- - -" }}</span>
        </div>

        <div class="nav-text text-muted col-8 p-0 pl-2">
          <i class="i-user-plus nav-icon"></i>
          <span>{{ $u2d(arr["a_time"], "DD.MM - LT") || "- - -" }}</span>
        </div>

        <div class="nav-text text-muted col-4 p-0">
          <IconBase
            :viewBox="'-73 0 512 512'"
            :width="15"
            :height="15"
            style="margin-right: 0px"
            ><Click
          /></IconBase>
          <span>{{ arrTransitions || "- - -" }}</span>
        </div>
      </nav>
      <mega-modal
        ref="business-qr"
        class="modal-sm"
        :active="modal"
        :center="true"
        @onClose="modal = false"
        @click="modal = !modal"
      >
        <qr-code :uri="'type=arr&id=' + arr['idt_arp']" />
      </mega-modal>
    </mega-card>
  </div>
</template>

<script>
import IconBase from "../../../../components/icons/IconBase";
import Click from "../../../../components/icons/set/Click";
import ScanQR from "../../../../components/icons/set/ScanQR";
import QrCode from "../../../components/QrCode";

export default {
  name: "BusinessCard",
  props: {
    arr: Object
  },
  data() {
    return {
      arrLikes: 0,
      arrTransitions: 0,
      arrRoundViews: 0,
      modal: false
    };
  },
  mounted() {
    this.getArrLikes();
    this.getArrTransitions();
    this.getArrRoundViews();
  },
  components: {
    IconBase,
    Click,
    ScanQR,
    QrCode
  },
  methods: {
    getArrLikes() {
      this.$api.v2base
        .get("/stat/arp/roundlikes/year", {
          params: {
            idt_arp_owner: this.arr.idt_arp,
            start_date: "02-01-2006 15:04:05"
          }
        })
        .then(response => {
          this.arrLikes = response.data[0].count;
        });
    },

    getArrTransitions() {
      this.$api.v2base
        .get("/stat/arp/transitions/year", {
          params: {
            idt_arp_owner: this.arr.idt_arp,
            start_date: "02-01-2006 15:04:05"
          }
        })
        .then(response => {
          this.arrTransitions = response.data[0].count;
        });
    },

    getArrRoundViews() {
      this.$api.v2base
        .get("/stat/arp/roundviews/year", {
          params: {
            idt_arp_owner: this.arr.idt_arp,
            start_date: "02-01-2006 15:04:05"
          }
        })
        .then(response => {
          this.arrRoundViews = response.data[0].count;
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.nav-icon
  margin-right: 7px !important
.qr-scan
  position: absolute
  top: 5px
  right: -8px
</style>
