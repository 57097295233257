var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.token
    ? _c(
        "div",
        {
          staticClass: "admin-page bg-lighter",
          class: {
            "side-menu-mini": _vm.menu.isMini,
            "side-menu-open": _vm.menu.isOpen
          }
        },
        [
          _c("page-sidebar"),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "admin-body d-flex flex-column",
              class: { maxHeight: _vm.$route.name == "Channels" },
              staticStyle: { "min-height": "100vh" }
            },
            [
              _c("page-navbar"),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: { parentHeight: _vm.$route.name == "Channels" },
                  staticStyle: { flex: "1" }
                },
                [
                  _c(
                    "no-ssr",
                    [
                      _c(
                        "keep-alive",
                        { attrs: { include: ["UsersPage", "RoundsPage"] } },
                        [
                          !_vm.$store.state.isBoundAdmin ||
                          _vm.$store.state.selectedSn
                            ? _c("router-view")
                            : _c("mega-loading", {
                                staticClass:
                                  "d-flex h-100 w-100 align-items-center justify-content-center"
                              })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("page-footer")
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }