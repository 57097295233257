<template>
  <footer class="small bg-white">
    <div class="container-fluid d-flex justify-content-between py-3">
      <div class="text-muted">
        <router-link to="/">&copy;ARROUND admin</router-link>
        <span>{{ $root.year }}</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter"
};
</script>
