var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M3.22222 4.33333H18.7778M5.44444 1H16.5556M3.22222 21H18.7778C20.0051 21 21 20.0051 21 18.7778V9.88889C21 8.66159 20.0051 7.66667 18.7778 7.66667H3.22222C1.99492 7.66667 1 8.66159 1 9.88889V18.7778C1 20.0051 1.99492 21 3.22222 21Z",
      stroke: "#fff",
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }