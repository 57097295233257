var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M17.5 0.5C22.547 0.5 27.051 2.724 30.162 6.218L32.493 4.029L34.078 5.811L18.638 23.402H16.856L8.11402 13.546L9.55702 12.018L17.563 18.057L27.846 8.396C25.249 5.461 21.477 3.595 17.25 3.595C9.41402 3.595 3.06202 9.948 3.06202 17.784C3.06202 25.62 9.41502 31.972 17.25 31.972C25.085 31.972 31.438 25.62 31.438 17.784C31.438 16.327 31.217 14.921 30.809 13.598L33.39 11.5C34.095 13.369 34.499 15.385 34.499 17.5C34.499 26.889 26.888 34.5 17.499 34.5C8.11002 34.5 0.499023 26.889 0.499023 17.5C0.499023 8.111 8.11102 0.5 17.5 0.5Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }