import { render, staticRenderFns } from "./SidePortal.vue?vue&type=template&id=0ca089b5&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ca089b5')) {
      api.createRecord('0ca089b5', component.options)
    } else {
      api.reload('0ca089b5', component.options)
    }
    module.hot.accept("./SidePortal.vue?vue&type=template&id=0ca089b5&", function () {
      api.rerender('0ca089b5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icons/set/SidePortal.vue"
export default component.exports