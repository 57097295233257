var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("path", {
    attrs: {
      d:
        "M0.5 32.349L1.5 13.349L3.5 11.349H11.881L11.602 5.488L8.5 7.349L7.5 6.349L13.5 0.348999L19.5 6.349L18.5 7.349L15.398 5.488L14.5 24.349H12.5L12.004 13.935H4.086L3.087 31.349L23.913 32.349L22.914 13.935H17.647L17.845 11.349H23.5L25.5 13.349L26.5 32.349L24.5 34.349H2.5L0.5 32.349Z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }