var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("g", [
    _c("path", { attrs: { d: "m450 270h30v30h30v-60h-60z" } }),
    _c("path", { attrs: { d: "m360 240h60v30h-60z" } }),
    _c("path", { attrs: { d: "m270 240h60v30h-60z" } }),
    _c("path", { attrs: { d: "m180 240h60v30h-60z" } }),
    _c("path", { attrs: { d: "m90 240h60v30h-60z" } }),
    _c("path", { attrs: { d: "m0 300h30v-30h30v-30h-60z" } }),
    _c("path", { attrs: { d: "m480 390v30h-30v30h60v-60z" } }),
    _c("path", { attrs: { d: "m30 390h-30v60h60v-30h-30z" } }),
    _c("path", { attrs: { d: "m360 420h60v30h-60z" } }),
    _c("path", { attrs: { d: "m270 420h60v30h-60z" } }),
    _c("path", { attrs: { d: "m180 420h60v30h-60z" } }),
    _c("path", { attrs: { d: "m90 420h60v30h-60z" } }),
    _c("path", { attrs: { d: "m0 330h30v30h-30z" } }),
    _c("path", { attrs: { d: "m480 330h30v30h-30z" } }),
    _c("path", {
      attrs: {
        d:
          "m143.033 304.394-21.213 21.213-21.214-21.213-21.212 21.212 21.213 21.214-21.213 21.213 21.212 21.213 21.214-21.213 21.213 21.213 21.213-21.213-21.213-21.213 21.213-21.214z"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "m276.894 304.394-21.214 21.213-21.213-21.213-21.213 21.212 21.213 21.214-21.213 21.213 21.213 21.213 21.213-21.213 21.214 21.213 21.212-21.213-21.213-21.213 21.213-21.214z"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "m409.394 304.394-21.214 21.213-21.213-21.213-21.213 21.212 21.213 21.214-21.213 21.213 21.213 21.213 21.213-21.213 21.214 21.213 21.212-21.213-21.213-21.213 21.213-21.214z"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "m50.621 210h3.758c27.912 0 50.621-22.708 50.621-50.621v-9.379h-30v9.379c0 11.371-9.251 20.621-20.621 20.621h-3.758c-11.37 0-20.621-9.25-20.621-20.621v-48.758c0-11.371 9.251-20.621 20.621-20.621h3.758c11.37 0 20.621 9.25 20.621 20.621v9.379h30v-9.379c0-27.913-22.709-50.621-50.621-50.621h-3.758c-27.912 0-50.621 22.708-50.621 50.621v48.758c0 27.913 22.709 50.621 50.621 50.621z"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "m185.621 210h3.758c27.912 0 50.621-22.708 50.621-50.621v-48.758c0-27.913-22.709-50.621-50.621-50.621h-3.758c-27.912 0-50.621 22.708-50.621 50.621v48.758c0 27.913 22.709 50.621 50.621 50.621zm-20.621-99.379c0-11.371 9.251-20.621 20.621-20.621h3.758c11.37 0 20.621 9.25 20.621 20.621v48.758c0 11.371-9.251 20.621-20.621 20.621h-3.758c-11.37 0-20.621-9.25-20.621-20.621z"
      }
    }),
    _c("path", {
      attrs: {
        d:
          "m375 159.379v-48.758c0-27.913-22.709-50.621-50.621-50.621h-54.379v150h54.379c27.912 0 50.621-22.708 50.621-50.621zm-75-69.379h24.379c11.37 0 20.621 9.25 20.621 20.621v48.758c0 11.371-9.251 20.621-20.621 20.621h-24.379z"
      }
    }),
    _c("path", {
      attrs: { d: "m510 90v-30h-105v150h105v-30h-75v-30h55v-30h-55v-30z" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }