var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mega-card",
    {
      staticClass: "card-border card-round rounded",
      class: { "overlay-10 active": _vm.isSelected },
      staticStyle: { position: "initial" },
      style: _vm.isSelected && "background: #eff5fd !important"
    },
    [
      !_vm.noAuthor
        ? _c("div", { staticClass: "navbar py-3 user-container" }, [
            _c(
              "div",
              { staticClass: "container-fluid" },
              [
                _vm.selectable
                  ? _c(
                      "span",
                      {
                        staticClass: "btn p-0 checker",
                        attrs: { "data-click": "ignore" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("select-force", _vm.id)
                          }
                        }
                      },
                      [
                        _vm.isSelected
                          ? _c(
                              "IconBase",
                              {
                                staticClass: "mr-0",
                                attrs: {
                                  height: "13.632",
                                  width: "14",
                                  viewBox: "0 0 34 34"
                                }
                              },
                              [_c("FullfilledCheck")],
                              1
                            )
                          : _c(
                              "IconBase",
                              {
                                staticClass: "mr-0",
                                attrs: {
                                  height: "13.632",
                                  width: "14",
                                  viewBox: "0 0 34 34"
                                }
                              },
                              [_c("EmptyCheck")],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "navbar-label w-100 text-dark",
                    attrs: {
                      to: { name: _vm.type, params: { id: _vm.author.id } }
                    }
                  },
                  [
                    _c("mega-image", {
                      staticClass: "rounded-full navbar-avatar mr-3",
                      staticStyle: { overflow: "hidden" },
                      attrs: { ratio: "1x1", small: "", src: _vm.author.avatar }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "content d-flex",
                        staticStyle: { width: "calc(100% - 78px)" }
                      },
                      [
                        _c("small", { staticClass: "strong text-overflow" }, [
                          _vm.type === "Business"
                            ? _c("i", {
                                staticClass:
                                  "i-globe text-muted text-arround align-middle mr-1"
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              _vm._v(
                                _vm._s(_vm.author.name) + "\n              "
                              ),
                              _vm.author.verified
                                ? _c(
                                    "IconBase",
                                    {
                                      attrs: {
                                        viewBox: "0 0 312 311",
                                        iconColor: "#45C1C9"
                                      }
                                    },
                                    [_c("UserVerified")],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-center" },
                          [
                            _c(
                              "small",
                              { staticClass: "text-muted text-overflow" },
                              [
                                _vm._v(
                                  "@" +
                                    _vm._s(_vm.author.username) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "media p-relative" }, [
        _vm.video_link
          ? _c(
              "div",
              { staticClass: "card-media card-video" },
              [
                _c("mega-image", { attrs: { ratio: "3x4", src: _vm.preview } }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "media-overlay overlay-50 overlay-dark" },
                  [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "span",
                        { staticClass: "sticker sticker-lg bg-white" },
                        [_c("i", { staticClass: "i-play" })]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "media-overlay overlay-90 overlay-dark media-video",
                    on: { mouseenter: _vm.play, mouseleave: _vm.pause }
                  },
                  [
                    _c("router-link", {
                      staticClass: "p-absolute fill-parent z-3",
                      attrs: { to: "/round/" + _vm.id }
                    }),
                    _vm._v(" "),
                    _c("video", {
                      ref: "video",
                      staticClass: "video-player fill-parent h-100",
                      staticStyle: { "object-fit": "cover" },
                      attrs: {
                        src: _vm.video_link,
                        poster: _vm.preview,
                        preload: "none",
                        loop: "",
                        muted: ""
                      },
                      domProps: { muted: true }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.video_link
          ? _c(
              "div",
              { staticClass: "card-media" },
              [
                _vm.youtube
                  ? _c(
                      "div",
                      { staticClass: "media-overlay overlay-50 overlay-dark" },
                      [
                        _c("div", { staticClass: "text-center" }, [
                          _c(
                            "span",
                            { staticClass: "sticker sticker-lg bg-white" },
                            [_c("i", { staticClass: "i-youtube-solid" })]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("router-link", {
                  staticClass: "p-absolute fill-parent z-3",
                  attrs: { to: "/round/" + _vm.id }
                }),
                _vm._v(" "),
                _c("mega-image", { attrs: { ratio: "3x4", src: _vm.preview } })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { attrs: { "data-click": "ignore" } }, [
        _c(
          "nav",
          { staticClass: "nav font-small d-flex justify-content-between" },
          [
            _c("div", { staticClass: "d-flex" }, [
              _c("span", { staticClass: "p-3" }, [
                _c(
                  "span",
                  { staticClass: "m-0 d-flex justify-content-between" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "mx-0 pr-2",
                        staticStyle: {
                          "font-size": "10px",
                          "text-overflow": "initial"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "i-heart",
                          staticStyle: { "font-size": "14px" }
                        }),
                        _vm._v("\n              " + _vm._s(_vm.likes))
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "likes-input align-self-center",
                      staticStyle: { width: "7ch", height: "3ch" },
                      attrs: {
                        type: "number",
                        placeholder: _vm.$t("round_likes_input")
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        },
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.changeLikes.apply(null, arguments)
                        }
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "m-0 d-flex justify-content-between" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "mx-0 pr-2",
                        staticStyle: {
                          "font-size": "10px",
                          "text-overflow": "initial"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "i-eye",
                          staticStyle: { "font-size": "14px" }
                        }),
                        _vm._v(" " + _vm._s(_vm.views))
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "views-input align-self-center",
                      staticStyle: { width: "7ch", height: "3ch" },
                      attrs: {
                        type: "number",
                        placeholder: _vm.$t("round_views_input")
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                        },
                        keydown: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.changeViews.apply(null, arguments)
                        }
                      }
                    })
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "btn ml-auto",
                  staticStyle: {
                    "font-size": "10px",
                    "text-overflow": "initial"
                  }
                },
                [
                  _c("i", {
                    staticClass: "i-message-circle",
                    staticStyle: { "font-size": "14px" }
                  }),
                  _vm._v("\n          " + _vm._s(_vm.comments))
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex mt-1" },
              [
                _c(
                  "span",
                  {
                    staticClass: "p-0 btn btn-sm",
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.downloadRound.apply(null, arguments)
                      }
                    }
                  },
                  [
                    _c(
                      "IconBase",
                      {
                        staticClass: "mr-0",
                        attrs: {
                          height: "13.632",
                          width: "14",
                          viewBox: "0 0 27.02 26.31"
                        }
                      },
                      [_c("Download")],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn p-0 btn-sm",
                    attrs: { to: "/round/" + _vm.id },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c(
                      "IconBase",
                      {
                        staticClass: "mr-0",
                        attrs: {
                          height: "13.632",
                          width: "14",
                          viewBox: "0 0 26 34"
                        }
                      },
                      [_c("GoTo")],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c("nav", { staticClass: "nav flex-column font-small" }, [
          _c("div", { staticClass: "nav-text d-flex" }, [
            _c("i", {
              staticClass: "i-map-pin text-dark",
              class: { "opacity-60": !_vm.address || _vm.is_location_hidden },
              staticStyle: { "margin-right": "5px" }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                class: { "opacity-60": !_vm.address || _vm.is_location_hidden }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.is_location_hidden
                      ? _vm.$t("location_hidden")
                      : _vm.address
                      ? _vm.address
                      : _vm.$t("no_address")
                  )
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { "flex-grow": "1" } }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "text-overflow",
                staticStyle: { "font-size": "8px" }
              },
              [
                _c("span", { staticStyle: { "vertical-align": "sub" } }, [
                  _vm._v(_vm._s(_vm._f("u2d")(_vm.date, "DD.MM.YYYY LT")))
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "nav-text text-muted d-flex justify-content-around align-items-center mb-1 pl-2 pr-2"
            },
            [
              _vm.readyForPush || !_vm.promoted_at || !_vm.promoted
                ? _c(
                    "mega-button",
                    {
                      staticClass:
                        "btn btn-sm btn-primary overlay-10 align-self-start mx-2",
                      class: { "btn-light": _vm.advert },
                      attrs: { disabled: _vm.advert != "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs["to-top"].open()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.advert
                              ? _vm.$t("ads_round")
                              : _vm.$t("round_to_top")
                          ) +
                          "\n        "
                      )
                    ]
                  )
                : _c(
                    "div",
                    { staticStyle: { height: "30px", "margin-right": "6px" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "btn btn-sm z-3 text-white align-self-start",
                          staticStyle: {
                            background: "rgba(0,0,0,0.75)",
                            "user-select": "none"
                          },
                          attrs: { onMouseOver: "this.style.color='#FFFFFF'" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.timeUntilPushReady) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn btn-sm btn-light text-danger",
                          staticStyle: { "text-overflow": "initial" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$refs["delete-top-confirm"].open()
                            }
                          }
                        },
                        [
                          _c(
                            "IconBase",
                            {
                              staticStyle: {
                                "margin-right": "0",
                                overflow: "none"
                              },
                              attrs: {
                                height: "13.632",
                                width: "14",
                                viewBox: "0 0 28 33"
                              }
                            },
                            [_c("Delete")],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
              _vm._v(" "),
              _c("div", { staticStyle: { "flex-grow": "1" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "p-0 btn btn-sm",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.$refs["advertise"].open()
                    }
                  }
                },
                [
                  _c(
                    "IconBase",
                    {
                      staticClass: "mr-0",
                      attrs: {
                        height: "13.632",
                        width: "14",
                        viewBox: "0 0 512 512"
                      }
                    },
                    [
                      _c("Advertising", {
                        attrs: { fill: _vm.advert ? "#028cff" : "" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.promoted_at
                ? _c(
                    "IconBase",
                    {
                      staticClass: "d-inline",
                      style:
                        "margin-left: auto !important; margin-right: 0px; min-width: 15px",
                      attrs: {
                        height: "15",
                        width: "15",
                        viewBox: "0 0 800 800",
                        iconColor: "#45C1C9"
                      }
                    },
                    [_c("Promoted")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "delete-confirm",
        {
          ref: "delete-top-confirm",
          attrs: {
            "data-click": "ignore",
            title: _vm.$t("round_del_top_title"),
            text: _vm.$t("round_del_top_confirm")
          },
          on: { confirm: _vm.deleteFromTop }
        },
        [
          _c("span", { staticClass: "badge m-2" }, [
            _vm._v("#" + _vm._s(_vm.id))
          ])
        ]
      ),
      _vm._v(" "),
      _c("to-top", {
        ref: "to-top",
        attrs: { round: _vm.id, likes: Number(_vm.likes), enabled: true },
        on: {
          refreshRound: _vm.refreshRound,
          click: function($event) {
            $event.stopPropagation()
          }
        }
      }),
      _vm._v(" "),
      _c("advertise", {
        ref: "advertise",
        attrs: { round: _vm.data, enabled: true },
        on: {
          click: function($event) {
            $event.stopPropagation()
          },
          adcompUpdated: _vm.updateAdvert
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }