<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    @onClose="modal = false"
  >
    <mega-header :title="$t('new_model')" class="bg-lighter">
      <span class="text-muted">{{ $t("new_model_desc") }}</span>
    </mega-header>

    <div class="card-body pb-0">
      <mega-input
        :label="$t('new_model_name')"
        :valid="name.length > 0"
        v-model="name"
      />

      <!-- <mega-select :label="$t('round_type')"
                         :placeholder="$t('type_holder')"
                         v-model="round_type"
                         :valid="round_type !== undefined"
                         @select="group = 0"
                         :options="roundTypes"/> -->

      <mega-radio
        :label="$t('round_type')"
        :options="roundTypes"
        v-model="round_type"
        :current="round_type"
        @dropgrp="group = 0"
      />

      <group-select
        v-if="round_type > 3"
        :label="$t('group')"
        :type="round_type"
        :placeholder="$t('group_holder')"
        v-model="group"
      />
    </div>

    <div class="card-body">
      <div class="navbar">
        <div class="navbar-group ml-auto">
          <mega-button class="btn-white mr-2" @click="modal = false">{{
            $t("close")
          }}</mega-button>
          <mega-button
            class="btn-primary mr-2"
            :disabled="!name || round_type === undefined"
            @click="submit"
            >{{ $t("save") }}</mega-button
          >
        </div>
      </div>
    </div>
  </mega-modal>
</template>

<script>
import GroupSelect from "../components/group-select";
import RoundTypes from "../../data/round_types.json";

export default {
  data() {
    return {
      name: "",
      group: 0,
      round_type: undefined,
      modal: false,
      roundTypes: RoundTypes
    };
  },
  computed: {
    type() {
      let res = undefined;

      if (this.round_type === 2) res = "Image";
      if (this.round_type === 3) res = "Video";
      if (this.round_type === 4) res = "Model";
      if (this.round_type === 5) res = "Gif";
      if (this.round_type === 10) res = "Mask";
      if (this.round_type === 12) res = "Portal";

      return res;
    }
  },
  methods: {
    submit() {
      let data = {
        idc_round_type: this.round_type,
        name: this.name,
        idt_group: this.group,
        sn_id: this.$store.getters.selectedSnId
      };

      this.$api.v2
        .post("/model", data)
        .then(response => {
          let model_id = response.data.model.idt_model;

          let formData = new FormData();
          formData.append("idt_model", model_id);
          formData.append("name", this.name);

          this.$api.v2
            .post("/modif", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(response => {
              this.$router.push({
                name: this.type,
                params: {
                  model_id: model_id,
                  modification_id: response.data.modif.idt_model_modif
                }
              });
              this.modal = false;
            });
        })
        .catch(() => {
          this.$alert.danger("Oops.. Server error");
        });
    },

    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    open(params) {
      this.round_type = undefined;
      this.group = 0;
      this.name = "";

      this.modal = true;

      if (!params) return false;
      if (params.type) this.round_type = params.type;
      if (params.group) this.group = params.group;
    }
  },
  components: {
    GroupSelect
  }
};
</script>
