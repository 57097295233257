<template>
  <mega-modal
    ref="modal-2"
    class="modal-sm"
    :active="modal"
    :center="true"
    @onClose="modal = false"
  >
    <div class="card-body p-4" @keypress.enter="submit">
      <span class="form-label mr-3"> {{ $t("advert_duration") }} </span>
      <date-picker
        class="w-100"
        id="chartDate"
        style="width: 100%"
        type="datetimerange"
        range-separator="—"
        :start-placeholder="$t('graph_start_date')"
        :end-placeholder="$t('graph_end_date')"
        v-model="duration"
      />
      <mega-input
        class="mb-0 mt-3"
        type="text"
        :label="$t('advert_result_link')"
        :placeholder="'https://'"
        v-model="result_link"
      />
      <!-- <div class="d-flex flex-column mt-2">
        <span class="form-label mr-3">{{ $t("advert_place") }}</span>
        <form class="inputs">
          <label for="enter" class="form-label d-inline">{{
            $t("advert_on_app_enter")
          }}</label>
          <input
            type="radio"
            id="enter"
            name="advert"
            v-model="show_on_startup"
            value="true"
          />
          <label for="feed" class="form-label d-inline">{{
            $t("advert_on_feed")
          }}</label>
          <input
            type="radio"
            id="feed"
            name="advert"
            value="true"
            v-model="show_in_feed"
          />
        </form>
      </div> -->
      <mega-number
        class="mb-3 mt-3"
        type="number"
        :min="1000"
        :step="100"
        :help="'Не меньше 1000'"
        :label="$t('advert_frequency')"
        v-model="show_count"
      />
      <div class="navbar sm-down:flex-column-reverse md-up:justify-content-end">
        <mega-button
          v-if="round.adcomp"
          class="bg-danger sm-down:w-100 md-up:mr-2 text-white"
          @click="archiveAdvert"
          >{{ $t("del_adcomp") }}</mega-button
        >
        <mega-button
          class="bg-white sm-down:w-100 md-up:mr-2"
          @click="modal = false"
          >{{ $t("close") }}</mega-button
        >
        <mega-button
          class="btn-primary sm-down:w-100 sm-down:mb-3"
          @click="submit"
          :disabled="isDisabled"
          >{{ round.adcomp ? $t("change") : $t("advert_round") }}</mega-button
        >
      </div>
    </div>
  </mega-modal>
</template>

<script>
import { DatePicker } from "element-ui";
import locale from "element-ui/lib/locale";
import "element-ui/lib/theme-chalk/button.css";
import ru from "element-ui/lib/locale/lang/ru-RU";
import en from "element-ui/lib/locale/lang/en";
export default {
  props: ["round", "enabled", "likes"],
  components: {
    DatePicker
  },
  data() {
    return {
      modal: false,
      active: false,
      duration: this.round.adcomp
        ? [
            new Date(this.round.adcomp.starts_at),
            new Date(this.round.adcomp.ends_at)
          ]
        : "",
      result_link: this.round.adcomp ? this.round.adcomp.link : "",
      show_count: this.round.adcomp ? this.round.adcomp.show_count : 1000,
      show_on_startup: this.round.adcomp
        ? this.round.adcomp.show_on_startup
        : false,
      show_in_feed: this.round.adcomp ? this.round.adcomp.show_in_feed : true
    };
  },
  mounted() {
    locale.use(this.$i18n.locale === "ru" ? ru : en);
  },
  computed: {
    isDisabled() {
      return !this.duration || !this.result_link;
    }
  },
  methods: {
    archiveAdvert() {
      this.$api.v2base
        .put("round/adcomp/archive", {
          round_id: this.round.idt_round
        })
        .then(() => {
          this.close();
          this.$alert.success(this.$t("success"));
          this.$emit("adcompUpdated", "");
          this.duration = [];
          this.result_link = "";
          this.show_count = 1000;
        })
        .catch(() => {
          this.$alert.danger(this.$t("danger"));
        });
    },

    submit() {
      if (this.round.adcomp) {
        this.$api.v2base
          .put("round/adcomp", {
            adcomp_id: this.round.adcomp.id,
            link: this.result_link,
            show_count: this.show_count,
            show_on_startup: this.show_on_startup,
            show_in_feed: this.show_in_feed,
            starts_at: this.duration[0].getTime() / 1000,
            ends_at: this.duration[1].getTime() / 1000
          })
          .then(res => {
            this.$emit("adcompUpdated", res.data);
            this.close();
            this.$alert.success(this.$t("success"));
          })
          .catch(() => {
            this.$alert.danger(this.$t("danger"));
          });
      } else {
        this.$api.v2base
          .post("round/adcomp", {
            round_id: this.round.idt_round,
            link: this.result_link,
            show_count: Number(this.show_count),
            starts_at: this.duration[0].getTime() / 1000,
            ends_at: this.duration[1].getTime() / 1000,
            show_on_startup: this.show_on_startup,
            show_in_feed: this.show_in_feed
          })
          .then(res => {
            this.$emit("adcompUpdated", res.data);
            this.close();
            this.$alert.success(this.$t("success"));
          })
          .catch(() => {
            this.$alert.danger(this.$t("danger"));
          });
      }
    },

    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
    }
  }
};
</script>
